import React from 'react';
import TokenInput from 'react-customize-token-input';
import 'react-customize-token-input/dist/react-customize-token-input.css';
import { SpheraInput, SpheraDropdownEx, SpheraTooltip, SpheraModal } from "@sphera-cloud/component-ui-reactjs";

export function AddEditFilterModal(props) {
    const changeName = event => {
        let filter = props.selectedFilter;
        filter.filterName = event.value;
        props.setselectedFilter(filter);
    }
    const changeExactMatchFilterValue = value => {
        props.setMatchFilters(value)
    }

    const changeCc1IncludeValue = value => {
        props.setCc1Include(value)
    }

    const changeCc2IncludeValue = value => {
        props.setCc2Include(value)
    }

    const changeCc3IncludeValue = value => {
        props.setCc3Include(value)
    }

    const changeMfgIncludeValue = value => {
        props.setMfgInclude(value)
    }

    const changeTagIncludeValue = value => {
        props.setTagInclude(value)
    }

    const changeExactMatchExcludeValue = value => {
        props.setExactMatchExclude(value)
    }

    const changeCc1ExcludeValue = value => {
        props.setCc1Exclude(value)
    }

    const changeCc2ExcludeValue = value => {
        props.setCc2Exclude(value)
    }

    const changeCc3ExcludeValue = value => {
        props.setCc3Exclude(value)
    }

    const changeMfgExcludeValue = value => {
        props.setMfgExclude(value)
    }

    const changeTagExcludeValue = value => {
        props.setTagExclude(value)
    }

    return (
        <div>
            {props.show && (
                <SpheraModal className="AddEditFilterModal" title={props.localizations.AddEditFilter}
                    width={1000} height="910px" onClose={props.toggleModal}
                    onCancel={props.toggleModal} cancelText={props.localizations.Cancel} actionText={props.localizations.Save}
                    onAction={props.save} >
                    <div className="container">
                        <div className="row" style={{width: "102%", paddingTop: "10px", paddingBottom: "10px"}}>
                            <div className="col-3 modalFieldDiv">
                                <SpheraTooltip position="top">
                                    <SpheraInput
                                        title={props.localizations.Name}
                                        label={props.localizations.Name}
                                        name="name"
                                        required
                                        type="text"
                                        placeholder={props.localizations.Name}
                                        maxLength={30}
                                        value={props.selectedFilter.filterName}
                                        onChange={changeName}
                                    />
                                    </SpheraTooltip>
                            </div>
                            <div className="col-9 modalFieldDiv">
                                <SpheraDropdownEx wrap filterable label={props.localizations.LanguageFilter} type="multi-select" onChange={props.handleLocalesDropdownChange} options={props.localesDropdownData} width="100%" value={props.localesSelected} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.localizations.MatchFilterInclude}</label>
                                <TokenInput tokenValues={props.MatchFilters} onTokenValuesChange={changeExactMatchFilterValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.localizations.MatchFilterExclude}</label>
                                <TokenInput tokenValues={props.ExactMatchExclude} onTokenValuesChange={changeExactMatchExcludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.cc1 + " " + props.localizations.Include}</label>
                                <TokenInput tokenValues={props.Cc1Include} onTokenValuesChange={changeCc1IncludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.cc1 + " " + props.localizations.Exclude}</label>
                                <TokenInput tokenValues={props.Cc1Exclude} onTokenValuesChange={changeCc1ExcludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.cc2 + " " + props.localizations.Include}</label>
                                <TokenInput tokenValues={props.Cc2Include} onTokenValuesChange={changeCc2IncludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.cc2 + " " + props.localizations.Exclude}</label>
                                <TokenInput tokenValues={props.Cc2Exclude} onTokenValuesChange={changeCc2ExcludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.cc3 + " " + props.localizations.Include}</label>
                                <TokenInput tokenValues={props.Cc3Include} onTokenValuesChange={changeCc3IncludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.cc3 + " " + props.localizations.Exclude}</label>
                                <TokenInput tokenValues={props.Cc3Exclude} onTokenValuesChange={changeCc3ExcludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.mfg + " " + props.localizations.Include}</label>
                                <TokenInput tokenValues={props.MfgInclude} onTokenValuesChange={changeMfgIncludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.mfg + " " + props.localizations.Exclude}</label>
                                <TokenInput tokenValues={props.MfgExclude} onTokenValuesChange={changeMfgExcludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.tags + " " + props.localizations.Include}</label>
                                <TokenInput tokenValues={props.TagInclude} onTokenValuesChange={changeTagIncludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                            <div className="col-6 modalFieldDiv">
                                <label className="sphera-label-text">{props.titles.tags + " " + props.localizations.Exclude}</label>
                                <TokenInput tokenValues={props.TagExclude} onTokenValuesChange={changeTagExcludeValue} separators={['\n']} style={{ height: "80px" }} />
                            </div>
                        </div>
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}