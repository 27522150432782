import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardSubtitle } from "@progress/kendo-react-layout";
import { SpheraButtonEx, SpheraTooltip } from "@sphera-cloud/component-ui-reactjs";
import { Layout } from "../components/MasterPage/Layout";
import { AddEditPublicSiteModal } from "../components/Modals/AddEditPublicSiteModal";
import { LoadingPanel } from "../components/LoadingPanel";
import { PublicSitesToolbar } from "../components/PublicSitesToolbar";
import { ConfirmDeleteSiteDialog } from "../components/Modals/ConfirmDeleteSiteDialog";
import { apiRoutes } from "../services/apiRoutes";
import useFetchWithMsal from "../services/useFetchWithMsal";

export const PublicSite = (props) => {
    const [materialColumns, setmaterialColumns] = React.useState([]);
    const [documentColumns, setdocumentColumns] = React.useState([]);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [siteNameValid, setSiteNameValid] = React.useState(true);
    const [siteUrlValid, setSiteUrlValid] = React.useState(true);
    const [tokenValid, setTokenValid] = React.useState(true);

    const [pending, setPending] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);

    const [apiTokens, setApiTokens] = React.useState([]);
    const [isData, setIsData] = React.useState(false);
    const [editableCards, setEditableCards] = React.useState([]);
    const [selectedCard, setSelectedCard] = React.useState({});
    const [isAddSiteFormValid, setIsSiteFormValid] = React.useState(true);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const [isDuplicate, setIsDuplicate] = React.useState(false);
    const [filterSelected, setfilterSelected] = React.useState(0);
    const [filtersDropdownData, setfiltersDropdownData] = React.useState([]);

    const [colorValue, setcolorValue] = React.useState("rgb(0, 97, 98)");
    const [DefaultTextValue, setDefaultTextValue] = React.useState("");

    const [uploadedFile, setUploadedFile] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState("");
    const [updateLogo, setUpdateLogo] = React.useState(false);
    const [logoUrl, setlogoUrl] = React.useState(false);
    const [loadingLogo, setloadingLogo] = React.useState(false);

    const changeHandler = (event) => {
        let [file] = event.target.files;
        if (file.uid)
            file = file.getRawFile();
        if (file) {
            var reader = new FileReader();
            reader.onload = function (readerEvt) {
                setSelectedFile(btoa(readerEvt.target.result));
                setUploadedFile(URL.createObjectURL(file));
                setUpdateLogo(true);
            };
            reader.readAsBinaryString(file);
        }
    };


    const removeLogo = (event) => {
        setUpdateLogo(true);
        setlogoUrl(null);
        setUploadedFile(null);
    };

    const handlecolorValueChange = (event) => {
        setcolorValue(event.value);
    };

    const handleDefaultTextValueChange = (event) => {
        setDefaultTextValue(event.html);
    };

    const [selectedSite, setselectedSite] = React.useState({});

    const listToTree = (list) => {
        var map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].phraseSection.phraseSectionTypeId] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.phraseSection.phraseSectionType.parentPhraseSectionTypeId !== null) {
                let parent = list[map[node.phraseSection.phraseSectionType.parentPhraseSectionTypeId]];
                if (parent)
                    parent.children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    const toggleEdit = (card) => {
        setloadingLogo(true);
        setUploadedFile(null);
        let neweditableCards = editableCards;
        const currentEditableIndex = neweditableCards.findIndex(
            (c) => c.site.id === card.site.id
        );

        const updatedEditable = { ...neweditableCards[currentEditableIndex] };

        const newEditables = [
            ...neweditableCards.slice(0, currentEditableIndex),
            updatedEditable,
            ...neweditableCards.slice(currentEditableIndex + 1),
        ];

        updatedEditable.site.customerPortalPhraseSections = listToTree(updatedEditable.site.customerPortalPhraseSections);
        setEditableCards(newEditables);
        setselectedSite(JSON.parse(JSON.stringify(updatedEditable.site)));
        updatedEditable.site.customerPortalPhraseSections.forEach(x => {
            x.title = x.phraseSection.phraseSectionType.name;
            x.Include = x.include;
            x.PropertyCategoryId = x.phraseSectionTypeId;
        });
        setPhraseSectionCustomer(updatedEditable.site.customerPortalPhraseSections.sort((a, b) => a.sortOrder - b.sortOrder));

        setcolorValue(card.site.headerColor);
        setDefaultTextValue(card.site.defaultText ?? "");
        let filter = filtersDropdownData.find(x => card.site.publicSiteFilters.length > 0 && x.value === card.site.publicSiteFilters[0].customerFilterId);
        if (filter == null)
            filter = filtersDropdownData[0];
        setfilterSelected(filter);
        toggle(card.site.id);
        setSelectedCard(JSON.parse(JSON.stringify(updatedEditable)));

        setlogoUrl(card.site.logoUrl);
    };

    let navigate = useNavigate();

    const includecustomcolumn1Change = (columnId, gridId) => {
        if (gridId === 1) {
            let columns = materialColumns;
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].columnId === columnId) {
                    columns[i].selected = !columns[i].selected;

                    if (columns[i].customerPortalColumns.length === 0)
                        columns[i].customerPortalColumns.push({ CustomerPortalColumnId: 0, customerColumnId: materialColumns[i].customerColumnId });

                    columns[i].customerPortalColumns.selected = columns[i].selected;
                }
            }
            setmaterialColumns(columns);
        }
        else {
            let columns = documentColumns;
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].columnId === columnId) {
                    columns[i].selected = !columns[i].selected;

                    if (columns[i].customerPortalColumns.length === 0)
                        columns[i].customerPortalColumns.push({ CustomerPortalColumnId: 0, customerColumnId: documentColumns[i].customerColumnId });

                    columns[i].customerPortalColumns.selected = columns[i].selected;
                }
            }
            setdocumentColumns(columns);
        }
    };

    const { execute } = useFetchWithMsal({
        scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
    });

    const getPublicSites = () => {
        execute("GET", apiRoutes.publicSites.get)
            .then((json) => {
                setPending(false);
                if (json && json.length !== 0) {
                    setIsData(true);
                    const newEditableCards = [];
                    json?.forEach((site, key) =>
                        newEditableCards.push({ isEdit: false, site: site })
                    );
                    setEditableCards(newEditableCards);
                } else {
                    setIsData(false);
                }
            })
            .catch((error) => {
                setError(true);
                setErrorMessage(props.localizations.RetrieveSiteError);
            });
    };

    const getData = () => {
        setPending(true);

        getPublicSites();

        execute("GET", `${apiRoutes.tokens.get}?filters=IsEnabled==true`).then(
            (json) => {
                const s = new Set(json);
                const options = [...s].map((x, i) => {
                    return { value: x.id, label: x.name };
                });
                setApiTokens(options);
            }
        );
        
        execute("GET", apiRoutes.filter.GetFilters).then(
            (json) => {
                if (json != null) {
                    for (var i = 0; i < json.length; i++) {
                        json[i].label = json[i].filterName;
                        json[i].value = json[i].id;
                    }
                    json.unshift({ label: props.localizations.NoFilter ?? "-- No Filter --", value: 0 });
                    setfiltersDropdownData(json);
                }
            }
        );
    };

    const handleOpenSite = (url) => {
        window.open(
            url,
            "Public Preview",
            "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no"
        );
    };

    const base64ToArrayBuffer = (base64) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    const toggle = (publicSiteId) => {
        setUpdateLogo(false);
        setUploadedFile(null);
        setSiteNameValid(true);
        setSiteUrlValid(true);
        setTokenValid(true);
        setShowModal(!showModal);
        setSelectedCard({});
        if (publicSiteId == null || publicSiteId.type === 'click')
            return;
        execute("GET", apiRoutes.column.GetPortalColumns(publicSiteId))
            .then((json) => {
                setPending(false);
                if (json) {
                    let matColumns = [];
                    let docColumns = [];
                    for (var i = 0; i < json.length; i++) {
                        let title = json[i].column.columnPropertyValues.find(x => x.columnPropertyId === 1).columnPropertyValue1;
                        json[i].title = title;
                        if (json[i].columnLocalizations.length > 0) {
                            let localizationEn = json[i].columnLocalizations.find(x => x.localeId == 1033);
                            let localization = json[i].columnLocalizations.find(x => x.localeId == props.languageValue.localeId);
                            if (localization)
                                json[i].title = localization.localization;
                            else if (localizationEn)
                                json[i].title = localizationEn.localization;
                            else
                                json[i].title = json[i].columnLocalizations[0].localization;

                            if (!json[i].title)
                                json[i].title = title;
                        }
                        if (!json[i].title)
                            json[i].title = json[i].column.field;

                        json[i].field = json[i].column.field;
                        let alwaysShow = json[i].column.columnPropertyValues.find(x => x.columnPropertyId === 4);
                        json[i].alwaysShow = alwaysShow != null && alwaysShow.columnPropertyValue1 === "true";
                        
                        json[i].width = 200;
                        json[i].order = json[i].columnOrder;
                        if (json[i].customerPortalColumns.length > 0) {
                            json[i].selected = json[i].customerPortalColumns[0].selected;
                            json[i].order = json[i].customerPortalColumns[0].order;
                            json[i].width = json[i].customerPortalColumns[0].width;
                        }

                        if (json[i].column.gridId === 1)
                            matColumns.push(json[i]);
                        else
                            docColumns.push(json[i]);
                    }
                    setmaterialColumns(matColumns.sort((a, b) => a.order - b.order));
                    setdocumentColumns(docColumns.sort((a, b) => a.order - b.order));
                }
            })
            .catch((error) => {
                setError(true);
                setErrorMessage(props.localizations.RetrieveSiteError);
            });
        execute("GET", apiRoutes.publicSites.GetSiteLogo(publicSiteId))
            .then((json) => {
                if (json != null) {
                    if (json.logo) {
                        var arrrayBuffer = base64ToArrayBuffer(json.logo);
                        var blob = new Blob([arrrayBuffer], { type: 'image/png' });
                        var newlogoUrl = URL.createObjectURL(blob);
                        setlogoUrl(newlogoUrl);
                    }
                    setloadingLogo(false);
                }
            })
            .catch((error) => {
                setError(true);
                setErrorMessage(props.localizations.RetrieveSiteError);
            });
    };

    const validateSite = (e) => {
        if (!e || selectedSite.name != null) {
            setSiteNameValid(Boolean(selectedSite.name));
            const isValidUrl = Boolean(
                new URL(`${props.portalBaseDomain}/${selectedSite.url}`)
            );
            setSiteUrlValid(Boolean(selectedSite.url) && isValidUrl && !isDuplicate);
            setTokenValid(Boolean(selectedSite?.apiToken?.id || selectedSite?.apiToken?.value));

            var valid =
                Boolean(selectedSite.name) &&
                Boolean(selectedSite.url) &&
                Boolean(selectedSite.apiToken?.id || selectedSite.apiToken?.value);

            return valid;
        } else {
            setSiteNameValid(Boolean(e.name));
            const isValidUrl = Boolean(
                new URL(`${props.portalBaseDomain}/${e.url}`)
            );
            setSiteUrlValid(Boolean(e.url) && isValidUrl && !isDuplicate);
            setTokenValid(Boolean(e.token?.value));

            var valid =
                Boolean(e.name) &&
                Boolean(e.url) &&
                Boolean(e.token?.value);

            return valid;
        }
    };

    const addSiteClick = (e) => {
        setselectedSite({});
        let sections = [];
        props.customer.PhraseSections.forEach(x => {
            sections.push({
                title: x.PhraseSectionType.Name,
                PhraseSectionTypeId: x.PhraseSectionTypeId,
                Include: true,
                phraseSection: x,
                phraseSectionCustomerPortalId: 0,
                phraseSectionId: x.PhraseSectionId,
                publicPortalId: "00000000-0000-0000-0000-000000000000",
                sortOrder: x.sortOrder
            });
        })
        setPhraseSectionCustomer(sections.sort((a, b) => a.sortOrder - b.sortOrder));
        
        setcolorValue("rgb(0, 97, 98)");
        setDefaultTextValue("");
        setfilterSelected(0);

        setUpdateLogo(false);
        setlogoUrl(null);
        setUploadedFile(null);
        toggle("00000000-0000-0000-0000-000000000000");
    };
    const modalCancelClick = () => { toggle(); };

    const propscheckChange = (phraseSectionTypeId) => {
        PhraseSectionCustomer.forEach(x => {
            if ((x.phraseSection.phraseSectionTypeId != null && x.phraseSection.phraseSectionTypeId === phraseSectionTypeId) || (x.phraseSection.PhraseSectionTypeId != null && x.phraseSection.PhraseSectionTypeId === phraseSectionTypeId))
                x.Include = !x.Include;
        });

        setPhraseSectionCustomer(PhraseSectionCustomer);
    };

    const [PhraseSectionCustomer, setPhraseSectionCustomer] = React.useState(props.customer.PhraseSectionCustomer ?? []);
    const propOnDragOver = event => {
        setPhraseSectionCustomer(event.newState);
    };
    const propOnNavigate = event => {
        setPhraseSectionCustomer(event.newState);
    };

    const modalSaveClick = (e) => {
        if (!validateSite(e)) {
            setErrorMessage(props.localizations.NotAllFieldsValid);
            setError(true);
            return;
        }

        let order = 1;
        let customerPortalColumns = [];
        for (var i = 0; i < materialColumns.length; i++) {
            if (materialColumns[i].customerPortalColumns.length === 0)
                materialColumns[i].customerPortalColumns.push({});
            materialColumns[i].customerPortalColumns[0].Order = order;
            order++;
            materialColumns[i].customerPortalColumns[0].customerPortalColumnId = 0;

            materialColumns[i].customerPortalColumns[0].width = materialColumns[i].width;
            materialColumns[i].customerPortalColumns[0].customerColumnId = materialColumns[i].customerColumnId;
            materialColumns[i].customerPortalColumns[0].selected = materialColumns[i].selected || materialColumns[i].alwaysShow;
            customerPortalColumns.push(materialColumns[i].customerPortalColumns[0]);
        }
        order = 1;
        for (var i = 0; i < documentColumns.length; i++) {
            if (documentColumns[i].customerPortalColumns.length === 0)
                documentColumns[i].customerPortalColumns.push({});
            documentColumns[i].customerPortalColumns[0].Order = order;
            order++;
            documentColumns[i].customerPortalColumns[0].customerPortalColumnId = 0;

            documentColumns[i].customerPortalColumns[0].width = documentColumns[i].width;
            documentColumns[i].customerPortalColumns[0].customerColumnId = documentColumns[i].customerColumnId;
            documentColumns[i].customerPortalColumns[0].selected = documentColumns[i].selected || documentColumns[i].alwaysShow;
            customerPortalColumns.push(documentColumns[i].customerPortalColumns[0]);
        }

        order = 1;
        PhraseSectionCustomer.forEach(x => {
            x.SortOrder = order++;
        });
        setPhraseSectionCustomer(PhraseSectionCustomer);

        const postData = {
            url: selectedSite.url.replaceAll(" ", ""),
            name: selectedSite.name,
            description: selectedSite.description,
            apiTokenId: selectedSite?.apiToken.value,
            PortalColumns: customerPortalColumns,
            HeaderColor: colorValue,
            DefaultText: DefaultTextValue,
            PortalCategories: PhraseSectionCustomer,
            logoData: selectedFile,
            updateLogo: updateLogo,
            filterSelected: filterSelected != null ? filterSelected.value : null
    };

        let siteId = "00000000-0000-0000-0000-000000000000";
        if (selectedSite.id)
            siteId = selectedSite.id;

        setPending(true);
        execute("GET", apiRoutes.publicSites.checkDuplicate(postData.url.replaceAll(" ", ""), siteId))
            .then((response) => {
                if (response && response.success) {
                    setIsDuplicate(true);
                    return true;
                }
                setIsDuplicate(false);

                if (selectedSite.id) {
                    updateSite(selectedSite, customerPortalColumns);
                    return;
                }

                execute("POST", apiRoutes.publicSites.post, postData)
                    .then((response) => {
                        if (!response) {
                            setErrorMessage(props.localizations.SiteNotCreated);
                            setError(true);
                        } else if (response?.errorType === 'Validation') {
                            setSiteUrlValid(false);
                            setError(true);
                            setErrorMessage(props.localizations.SiteExists);
                        } else {
                            setTimeout(function () {
                                setSuccess(false);
                            }, 5000);
                            setTokenValid(true);
                            setSiteUrlValid(true);
                            setSiteNameValid(true);
                            toggle();
                            setSuccess(true);
                            setError(false);
                            setPending(false);
                            setIsDuplicate(false);
                            getPublicSites();
                        }
                    })
                    .catch((err) => {
                        setError(err);
                        setErrorMessage(err);
                    })
                    .finally(() => {
                        getPublicSites();
                        setPending(false);
                        setTimeout(() => {
                            setError('');
                        }, 4000);
                    });
            });
    };

    const updateSite = (dataItem, customerPortalColumns) => {
        const updatedSite = { ...selectedCard };
        updatedSite.site.name = dataItem.name;
        updatedSite.site.description = dataItem.description;
        updatedSite.site.url = dataItem.url;
        updatedSite.site.apiToken = { id: dataItem.apiToken.id ?? dataItem.apiToken.value };

        setSelectedCard(updatedSite);

        if (!validateSite()) {
            setErrorMessage(props.localizations.NotAllFieldsValid);
            setError(true);
            return;
        }

        const putData = {
            url: selectedCard.site.url,
            name: selectedCard.site.name,
            description: selectedCard.site.description,
            apiTokenId: selectedCard?.site.apiToken?.id,
            PortalColumns: customerPortalColumns,
            HeaderColor: colorValue,
            DefaultText: DefaultTextValue,
            PortalCategories: PhraseSectionCustomer,
            logoData: selectedFile,
            updateLogo: updateLogo,
            filterSelected: filterSelected != null ? filterSelected.value : null
        };

        setPending(true);
        execute("PUT", apiRoutes.publicSites.put(selectedCard.site.id), putData)
            .then((response) => {
                setShowModal(!showModal);
                if (!response) {
                    setErrorMessage(props.localizations.SiteNotCreated);
                    setError(true);
                } else if (response?.errorType === 'Validation') {
                    setSiteUrlValid(false);
                    setError(true);
                    setErrorMessage(props.localizations.SiteExists);
                } else {
                    setTimeout(function () {
                        setSuccess(false);
                    }, 5000);
                    setTokenValid(true);
                    setSiteUrlValid(true);
                    setSiteNameValid(true);
                    setIsDuplicate(false);
                    setSuccess(true);
                    setError(false);
                    setPending(false);

                    getPublicSites();
                }
            })
            .catch((err) => {
                setError(true);
                setErrorMessage(props.localizations.UnableToUpdateSite);
                setPending(false);
            });
    };

    const toggleConfirmDeleteDialog = (event) => { setShowConfirmDelete(!showConfirmDelete); };

    const deleteSite = (card) => {
        setPending(true);
        execute("DELETE", apiRoutes.publicSites.delete(card.site.id)).then(
            (json) => {
                setPending(false);
                toggleConfirmDeleteDialog();
                setPending(true);

                getPublicSites();
            }
        );
    };

    const [gotData, setgotData] = React.useState(false);
    React.useEffect(() => {
        if (props.currentTenent.CustomerId != null && !props.currentTenent.IsEditCustomerPortalUser)
            navigate("/");
        if (gotData)
            return;

        setgotData(true);
    }, [execute]);

    React.useEffect(() => {
        if (gotData)
            getData();
    }, [gotData]);

    const [copyText, setCopyText] = React.useState("");
    const popoverAnchor = React.useRef(null);
    async function copyTextToClipboard(text) {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    };

    const handleCopyClick = (e, copiedText) => {
        copyTextToClipboard(copiedText)
            .then(() => {
                setCopyText(copiedText);
                e.target.className = "icon-ui-general-check";
                setTimeout(() => {
                    e.target.className = "icon-ui-general-copy"
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFilterDropdownChange = (e) => {
        setfilterSelected(e.value);
    };


    return (
        <Layout
            versionNumber={props.versionNumber} 
            currentTenent={props.currentTenent}
            localizations={props.localizations}
            changeLanguage={props.changeLanguage}
            languageValue={props.languageValue}
            success={success}
            setSuccess={setSuccess}
        >
            <PublicSitesToolbar
                localizations={props.localizations}
                addSiteClick={addSiteClick}
                setIsSiteFormValid={setIsSiteFormValid}
            ></PublicSitesToolbar>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: !isData ? "center" : "initial",
                    flexWrap: "wrap",
                    gap: "1%",
                }}
                className="cards"
            >
                {isData &&
                    !pending &&
                    editableCards.map((card, i) => {
                        return (
                            <Card
                                key={i}
                                style={{
                                    width: 340,
                                    boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                                    marginTop: "15px",
                                    border: "1px solid red",
                                    background: "#eeeeee",
                                }}
                            >
                                <CardHeader
                                    className="k-hbox"
                                    style={{
                                        background: "transparent",
                                    }}
                                >
                                    <div>
                                        <CardTitle
                                            style={{
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {card.site.name}
                                        </CardTitle>
                                        <CardSubtitle><span style={{ wordBreak: 'break-word' }}>{card.site?.description}</span></CardSubtitle>
                                    </div>
                                </CardHeader>
                                <CardBody className="border-bottom">
                                    <div>
                                        <span>
                                            <b>{props.localizations.Url}: </b>
                                        </span>
                                        <span style={{ wordBreak: 'break-word' }}>{props.portalBaseDomain}{card.site.url}</span>
                                        <SpheraTooltip position="top" title="Copy Url">

                                            <input type="hidden" value={copyText}></input>

                                            <span
                                                ref={popoverAnchor}
                                                style={{ marginLeft: "6px", fontSize: '1.5rem' }}
                                            ><i className="icon-ui-general-copy" style={{ background: "#009BDE" }} onClick={(e) => handleCopyClick(e, `${props.portalBaseDomain}${card.site.url}`)}></i></span>
                                        </SpheraTooltip>
                                        <SpheraTooltip position="top" title="Open Portal">
                                            <span
                                                title="Open Portal"
                                                style={{ marginLeft: "6px" }}
                                            ><i className="icon-ui-general-link" style={{ background: "#009BDE" }} onClick={(e) =>
                                                handleOpenSite(`${props.portalBaseDomain}${card.site.url}`)
                                            }></i></span>
                                        </SpheraTooltip>
                                        <br />
                                        <span>
                                            <b>{props.localizations.UsedToken}: </b>
                                        </span>
                                        {card.site?.apiToken?.name}
                                    </div>
                                </CardBody>
                                <CardActions className="mt-2 k-form-buttons">
                                    <div className="row col-12 p-0">
                                        <div className="col-6 float-left px-3">
                                            <SpheraButtonEx
                                                text={props.localizations.Delete}
                                                secondary={true}
                                                onClick={(event) => {
                                                    toggleConfirmDeleteDialog(event);
                                                    setSelectedCard(card);
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 float-right px-0 mx-0" style={{ paddingRight: '-10px!important' }}>
                                            <SpheraButtonEx
                                                text={props.localizations.Edit}
                                                primary={true}
                                                onClick={(event) => {
                                                    toggleEdit(card);
                                                }}
                                                style={{ float: 'right' }}
                                            />
                                        </div>
                                    </div>
                                </CardActions>
                            </Card>
                        );
                    })}
                {!isData && !pending && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginTop: "4rem",
                        }}
                    >
                        <div className="icon-item text-center">
                            <i className="icon-outline-search"></i>
                            <div
                                className="font-scale-24pt"
                                style={{ width: "120px", textTransform: "capitalize" }}
                            >
                                No Data
                            </div>
                        </div>
                    </div>
                )}

                {pending && !isData && (
                    <LoadingPanel localizations={props.localizations} />
                )}
            </div>

            <AddEditPublicSiteModal
                toggle={toggle} showModal={showModal} localizations={props.localizations} modalCancelClick={modalCancelClick} modalSaveClick={modalSaveClick}
                siteNameValid={siteNameValid} setSiteNameValid={setSiteNameValid} siteUrlValid={siteUrlValid} setSiteUrlValid={setSiteUrlValid}
                tokenValid={tokenValid} setTokenValid={setTokenValid} apiTokens={apiTokens} loginConfig={props.loginConfig} isAddSiteFormValid={isAddSiteFormValid}
                validateSite={validateSite} baseDomain={props.portalBaseDomain} isDuplicate={isDuplicate} materialColumns={materialColumns}
                documentColumns={documentColumns} includecustomcolumn1Change={includecustomcolumn1Change} setmaterialColumns={setmaterialColumns}
                setdocumentColumns={setdocumentColumns} selectedSite={selectedSite} setselectedSite={setselectedSite}
                colorValue={colorValue} DefaultTextValue={DefaultTextValue} handlecolorValueChange={handlecolorValueChange}
                handleDefaultTextValueChange={handleDefaultTextValueChange}
                propscheckChange={propscheckChange} propOnDragOver={propOnDragOver} propOnNavigate={propOnNavigate} PhraseSectionCustomer={PhraseSectionCustomer}
                removeLogo={removeLogo} changeHandler={changeHandler} uploadedFile={uploadedFile} logoUrl={logoUrl} loadingLogo={loadingLogo}
                error={error} handleFilterDropdownChange={handleFilterDropdownChange} filtersDropdownData={filtersDropdownData} filterSelected={filterSelected}
                setError={setError}
                errorMessage={errorMessage}
            />

            {showConfirmDelete && (
                <ConfirmDeleteSiteDialog
                    card={selectedCard}
                    toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
                    deleteSite={deleteSite}
                    localizations={props.localizations}
                ></ConfirmDeleteSiteDialog>
            )}
        </Layout>
    );
};
