import React from 'react';
import { Grid, GridColumn as Column, GridToolbar, GridColumnMenuWrapper } from "@progress/kendo-react-grid";
import { GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { SpheraButtonEx, SpheraSwitchToggle, SpheraTooltip } from "@sphera-cloud/component-ui-reactjs";
import dateFormats from "../../Data/dateFormats.json";
import documentDetailsGridColumns from "../../Data/documentDetailsGridColumns.json";
import { CustomColumnMenu } from "../CustomColumnMenu";
import { TextCellPopover } from './Cell/TextCell';
import { LoadingPanel } from '../LoadingPanel';
import { CheckboxColumnHeader } from "../CheckboxColumnHeader";

export function DocumentDetailsGrid(props) {
	let dateFormatFilter = "dd/MM/yyyy";
	if (props.customer.DateFormatId != null) {
		let format = dateFormats.find(x => x.value === props.customer.DateFormatId);
		if (format != null) {
			dateFormatFilter = format.text;
		}
	}

	const innerSetShowModal = (documentName) => { props.toggle(documentName + ".pdf") };

	const handlePopoverShow = (e) => {
		setShow(!show);
	};

	const [show, setShow] = React.useState(true);
	
	return (
		<>
			<div style={{ height: 'calc(100vh - 260px)', minHeight: '220px' }} className={!(props.currentTenent.RequiresNavMenu) ? 'gridAddMargin' : ''}>
					<Grid
						className="sphera-grid"
						style={{
							height: '100%',
							width: '100%'
						}}
						data={props.dataResult}
						{...props.dataState}
						onDataStateChange={props.dataStateChange}
						sortable={true}
						resizable={true}
						scrollable="scrollable"
						pageable={{
							buttonCount: 4,
							pageSizes: [25, 50, 100]
						}}
				>
					{(props.currentTenent.IsViewInactiveDocumentsUser || props.currentTenent.IsDeleteDocumentsUser || props.currentTenent.IsEditSensitivityUser || props.currentTenent.IsManageArchivedDocumentsUser) &&
						<GridToolbar>
							{localStorage.getItem("languages") && <span style={{ marginRight: "auto", color: "green" }}><i className="icon-ui-general-filter" style={{ background: "green" }} ></i> {props.localizations.FilteredOnLanguage}</span>}
							{(props.currentTenent.IsViewInactiveDocumentsUser && !props.searchArchive) && (
								<div style={{ marginRight: '10px' }}>
									<label className="k-label switch-label" style={{ marginRight: '4px', marginTop: '5px', cursor: 'pointer', color: '#0877A7' }}>{props.localizations.ShowInactive}
										<span style={{ marginLeft: '4px' }}>
											<SpheraSwitchToggle checked={props.status} onChange={props.handleStatusChange} />
										</span>
									</label>
								</div>)
							}	
							{((!props.searchArchive && (props.currentTenent.IsDeleteDocumentsUser || props.currentTenent.IsEditSensitivityUser)) || props.currentTenent.IsManageArchivedDocumentsUser) && (
								<SpheraButtonEx style={{ height: '32px' }} icon="icon-ui-general-settings" primary onClick={props.handleContextMenu} />)
							}
						</GridToolbar>
					}
					{props.documentColumns.map(
							(column, idx) =>
								column.show && (
									<Column
										key={idx}
										cell={(props2) =>
											<TextCellPopover
												field={column.field}
												isLink={column.IsLink}
												searchedPhrase=""
												linkClick={innerSetShowModal}
												clickId={props2.dataItem.documentId}
												documentData={props2.dataItem}
												DisplayValue={column.AdjustTimezone ? props2.dataItem[column.field + "Display"] : props2.dataItem[column.field]}
												customer={props.customer}
												isDate={column.IsDate}
												adjustTimezone={column.AdjustTimezone}
												isCheckbox={column.IsCheckbox}
												checkboxChange={props.checkboxChange}
											/>
										}
										onMouseMove={(e) => handlePopoverShow(e)}
										filterable={!(column.noFilterOrSort || column.Filter === "false")}
										sortable={!(column.noFilterOrSort || column.Filter === "false")}
										field={column.field}
										width={column.noFilterOrSort ? 50 : column.width}
										minResizableWidth={column.noFilterOrSort ? 50 : 100}
										title={column.noFilterOrSort ? " " : props.localizations[column.title]}
										filter={column.Filter !== "false" ? column.Filter : "text"}
										headerClassName={GridColumnMenuFilter.active(column.field, props.dataState.filter) ? 'active' : (column.noFilterOrSort ? "hiddenIcon" : '')}
										headerCell={(props2) => {
											if (column.IsCheckbox)
												return (<CheckboxColumnHeader {...props2} checkAllChange={props.checkAllChange} checkAllChecked={props.checkAllChecked} />);
											else if (column.Filter === "false")
												return (
													<span className="k-cell-inner">
														<span className="k-link" onClick={props2.onClick} >
															<SpheraTooltip anchor="target" position="top" title={column.Title} >
																<span className="k-column-title">{column.Title}</span>
															</SpheraTooltip>
															{props2.children}
														</span>
													</span>);
											else
												return (
													<span className="k-cell-inner">
														<span className="k-link" onClick={props2.onClick} >
															<SpheraTooltip anchor="target" position="top" title={column.Title} >
																<span className="k-column-title">{column.Title}</span>
															</SpheraTooltip>
															{props2.children}
														</span>
														<GridColumnMenuWrapper
															{...props2.columnMenuWrapperProps}
														></GridColumnMenuWrapper>
													</span>);
										}}
										columnMenu={(props2) => {
											return column.noFilterOrSort || column.Filter === "false" ? null :
												(
													<CustomColumnMenu {...props2}
														data={props.documents}
														checkboxFilter={column.CheckboxFilter}
														columns={documentDetailsGridColumns}
														useDateFilter={column.IsDate}
														dateFormatFilter={dateFormatFilter} />
												);
										}}
									/>
								)
						)}
					</Grid>
				{props.pending && <LoadingPanel localizations={props.localizations} />}
			</div>
		</>
	);
}