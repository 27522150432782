import React from 'react';
import { SpheraModal, SpheraDropdownEx } from '@sphera-cloud/component-ui-reactjs';
import { SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";

export function AssignFilterToGroupModal(props) {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">{props.localizations.Loading}</span>
            <SpheraSpinner version={1} color="dark-1" />
        </div>
    );

    return (
        <div>
            {props.showAssignToGroupModal && (
                <SpheraModal className="AssignFilterToGroup" title={props.localizations.AssignToGroup} width={400} height={300}
                    onClose={props.toggleAssignFilterToGroup}
                    onCancel={props.toggleAssignFilterToGroup}
                    cancelText={props.localizations.Cancel}
                    actionText={props.localizations.Save}
                    onAction={props.submitAssignFilterGroupFilter}
                >
                    <div className="AssignFilterToGroupDiv" >
                        <br/>
                        <SpheraDropdownEx wrap filterable label={props.localizations.Groups}
                            type="multi-select" onChange={props.handleGroupsDropdownChange} options={props.groups}
                            width="100%" value={props.groupsSelected} />
                        {props.modalPending && loadingPanel}
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}