import * as React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const DateFilterCell = (props) => {
    let dateBox1;
    let dateBox2;
    const min = new Date(2000, 2, 10);
    const onChange1 = (event) => {
        props.firstFilterProps.onChange({
            value: dateBox1.value,
            operator: props.firstFilterProps.operator,
            syntheticEvent: event.syntheticEvent,
        });
    };
    const operatorChange1 = (event) => {
        props.firstFilterProps.onChange({
            value: dateBox1.value,
            operator: event.value.operator,
            syntheticEvent: event.syntheticEvent,
        });
    };

    const onChange2 = (event) => {
        props.secondFilterProps.onChange({
            value: dateBox2.value,
            operator: props.secondFilterProps.operator,
            syntheticEvent: event.syntheticEvent,
        });
    };
    const operatorChange2 = (event) => {
        props.secondFilterProps.onChange({
            value: dateBox2.value,
            operator: event.value.operator,
            syntheticEvent: event.syntheticEvent,
        });
    };
    const andOrChange = (event) => {
        props.onLogicChange(event);
    };
    const value1 = props.firstFilterProps.value || null;
    const operatorValue1 = props.firstFilterProps.operators.find(x => x.operator === props.firstFilterProps.operator) || null;
    const value2 = props.secondFilterProps.value || null;
    const operatorValue2 = props.secondFilterProps.operators.find(x => x.operator === props.secondFilterProps.operator) || null;
    const andOr = props.logicValue || null;
    return (
        <div>
            <DropDownList
                data={props.firstFilterProps.operators}
                onChange={operatorChange1}
                value={operatorValue1}
                textField="text"
                dataItemKey="operator"
                style={{ marginBottom: "10px" }}
            />
            <br />
            <DatePicker
                className="Ds-filterdatepicker"
                min={min}
                format={props.dateFormatFilter}
                value={value1}
                ref={(date) => {
                    dateBox1 = date;
                }}
                onChange={onChange1}
                navigation={false}
            />
            <br />
            <DropDownList
                className="Ds-filterlogicpicker"
                data={props.logicData}
                onChange={andOrChange}
                value={andOr}
                textField="text"
                dataItemKey="operator"
                style={{ marginBottom: "10px" }}
            />

            <br />
            <DropDownList
                data={props.firstFilterProps.operators}
                onChange={operatorChange2}
                value={operatorValue2}
                textField="text"
                dataItemKey="operator"
                style={{ marginBottom: "10px" }}
            />
            <br />
            <DatePicker
                min={min}
                format={props.dateFormatFilter}
                value={value2}
                ref={(date) => {
                    dateBox2 = date;
                }}
                onChange={onChange2}
                navigation={false}
            />
        </div>
    );
};