import React from 'react';
import { SpheraModal } from '@sphera-cloud/component-ui-reactjs';
import { SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";

export function ArchiveDocumentsModal(props) {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">{props.localizations.Loading}</span>
            <SpheraSpinner version={1} color="dark-1" />
        </div>
    );

    return (
        <div>
            {props.showArchiveDocumentsModal && (
                <SpheraModal className="ArchiveDocumentsModal" title={props.localizations.ArchiveDocuments} width={450} height={300}
                    onClose={props.toggleArchiveDocuments}
                    onCancel={props.toggleArchiveDocuments}
                    cancelText={props.localizations.Cancel}
                    actionText={props.localizations.Save}
                    onAction={props.submitArchiveDocuments}
                >
                    <div className="ArchiveDocumentsModalDiv" >
                        <div>{props.localizations.ArchiveDocumentsConfirm}</div>
                        {props.modalPending && loadingPanel}
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}