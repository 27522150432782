import React from "react";
import { useNavigate } from "react-router-dom";
import { Collapse, CardHeader, CardBody, Card } from "reactstrap";
import { SpheraDropdownEx, SpheraInput, SpheraButtonEx, SpheraRadioButtonGroup, SpheraCheckbox } from "@sphera-cloud/component-ui-reactjs";
import { Sortable } from '@progress/kendo-react-sortable';
import { process } from "@progress/kendo-data-query";
import { LoadingPanel } from "../components/LoadingPanel";
import { CustomColumnLocalizationModal } from '../components/Modals/CustomColumnLocalizationModal';
import { CustomColumnWidthsModal } from '../components/Modals/CustomColumnWidthsModal';
import { SortableMaterialColumn } from '../components/SortableMaterialColumn';
import { SortableCustomerProperty } from '../components/SortableCustomerProperty';
import { Layout } from "../components/MasterPage/Layout";
import useFetchWithMsal from "../services/useFetchWithMsal";
import { apiRoutes } from "../services/apiRoutes";
import timezones from "../Data/timezones.json";
import dateFormats from "../Data/dateFormats.json";

export function Settings(props) {
    const [materialColumns, setmaterialColumns] = React.useState([]);
    const [documentColumns, setdocumentColumns] = React.useState([]);

    const onDragOver = event => {
        setmaterialColumns(event.newState);
    };
    const onNavigate = event => {
        setmaterialColumns(event.newState);
    };

    const docOnDragOver = event => {
        setdocumentColumns(event.newState);
    };
    const docOnNavigate = event => {
        setdocumentColumns(event.newState);
    };

    const propOnDragOver = event => {
        setPhraseSections(event.newState);
    };
    const propOnNavigate = event => {
        setPhraseSections(event.newState);
    };

    let navigate = useNavigate();

    const dateNow = new Date();
    const formatDate = (date, dateFormatId) => {
        switch (dateFormatId) {
            case dateFormats[0].value:
                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            case dateFormats[1].value:
                return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            case dateFormats[2].value:
                return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
            default:
                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
    };

    const [newId, setnewId] = React.useState(-1);
    const [DateFormatId, setDateFormatId] = React.useState(0);
    const [TimezoneId, setTimezoneId] = React.useState(0);
    const [CustomerName, setCustomerName] = React.useState("");
    const [displayDate, setdisplayDate] = React.useState("");
    const [pending, setPending] = React.useState(false);
    const [success, setsuccess] = React.useState(false);
    const [error, seterror] = React.useState(false);
    const [errorMessage, seterrorMessage] = React.useState("");
    const [corporateSettingsOpen, setcorporateSettingsOpen] = React.useState(true);
    const [publicSettingsOpen, setpublicSettingsOpen] = React.useState(true);
    const [languages, setlanguages] = React.useState([]);
    const [selectedColumn, setselectedColumn] = React.useState([]);

    const [showDetails, setshowDetails] = React.useState(false);
    const [showMaterial, setshowMaterial] = React.useState(false);

    const [defaultIngredientType, setdefaultIngredientType] = React.useState(0);
    const [defaultSensitivity, setdefaultSensitivity] = React.useState(1);
    const [preserveExisting, setpreserveExisting] = React.useState(true);

    const [showCustomColumnModal, setshowCustomColumnModal] = React.useState(false);

    const [disableSave, setdisableSave] = React.useState(false);

    const [showWidthModal, setshowWidthModal] = React.useState(false);
    const [PhraseSections, setPhraseSections] = React.useState(props.customer.PhraseSections ? JSON.parse(JSON.stringify(props.customer.PhraseSections)) : []);
    
    const handleFormatChange = (newDateFormat) => {
        setDateFormatId(newDateFormat.value.value);
        setdisplayDate(formatDate(dateNow, newDateFormat.value.value));
    };

    const [localizationsDataState, setlocalizationsDataState] = React.useState({ skip: 0, take: 25 });
    const [localizationsDataResult, setlocalizationsDataResult] = React.useState(
        process([], localizationsDataState)
    );
    const localizationsDataStateChange = (event) => {
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, event.dataState));
        setlocalizationsDataState(event.dataState);
    };

    const toggleWidthsModal = (isMaterial) => {
        setshowWidthModal(!showWidthModal);

        if (showWidthModal) {
            setshowDetails(false);
            setshowMaterial(false);
        }
        else {
            if (!isMaterial) {
                setshowDetails(true);
                setshowMaterial(false);
            }
            else {
                setshowMaterial(true);
                setshowDetails(false);
            }
        }
    }

    const handleTimezoneChange = (newTimezone) => { setTimezoneId(newTimezone.value.timezoneId); };
    const CompanyNameChange = (newValue) => { setCustomerName(newValue.value); };
    const customcolumn1Change = (e, columnId, gridId) => {
        let columns = documentColumns;
        if (gridId === 1)
            columns = materialColumns;
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].columnId === columnId) {
                if (columns[i].customerColumns[0].columnLocalizations.length === 0)
                    columns[i].customerColumns[0].columnLocalizations.push({ localeId: props.languageValue.localeId, customerColumnId: columns[i].customerColumns[0].customerColumnId, columnLocalizationId: newId, localization: e.target.value });
                else {
                    let found = false;
                    for (var j = 0; j < columns[i].customerColumns[0].columnLocalizations.length; j++) {
                        if (columns[i].customerColumns[0].columnLocalizations[j].localeId === props.languageValue.localeId) {
                            found = true
                            columns[i].customerColumns[0].columnLocalizations[j].localization = e.target.value;
                        }
                    }
                    if (!found)
                        columns[i].customerColumns[0].columnLocalizations.push({ localeId: props.languageValue.localeId, customerColumnId: columns[i].customerColumns[0].customerColumnId, columnLocalizationId: newId, localization: e.target.value });
                }
                setnewId(newId - 1);
            }
        }

        if (gridId === 1)
            setmaterialColumns(columns);
        else
            setdocumentColumns(columns);
    };
    const includecustomcolumn1Change = (columnId, gridId) => {
        if (gridId === 1) {
            let columns = materialColumns;
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].columnId === columnId)
                    columns[i].customerColumns[0].selected = !columns[i].customerColumns[0].selected;
            }
            setmaterialColumns(columns);
        }
        else {
            let columns = documentColumns;
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].columnId === columnId)
                    columns[i].customerColumns[0].selected = !columns[i].customerColumns[0].selected;
            }
            setdocumentColumns(columns);
        }
    };

    const toggleCustomColumn = (columnId, gridId) => {
        setshowCustomColumnModal(!showCustomColumnModal);
        if (gridId == null)
            return;
        let column = {};
        let columns = materialColumns;
        if (gridId === 2)
            columns = documentColumns;
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].columnId === columnId)
                column = columns[i];
        }
        if (column.customerColumns == null)
            column.customerColumns = [];
        if (column.customerColumns.length === 0)
            column.customerColumns.push({ ColumnId: columnId, columnLocalizations: [], customerColumnId: 0 });
        setselectedColumn(column);

        setlocalizationsDataState({ skip: 0, take: 25 });
        setlocalizationsDataResult(process(column.customerColumns[0].columnLocalizations, { skip: 0, take: 25 }));
    };

    const { execute } = useFetchWithMsal({
        scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
    });

    const publicSettingsOpenChange = () => {
        setpublicSettingsOpen(!publicSettingsOpen);
    };
    const corporateSettingsOpenChange = () => {
        setcorporateSettingsOpen(!corporateSettingsOpen);
    };

    const changedefaultIngredientType = (e) => {
        setdefaultIngredientType(e.value);
    }

    const changedefaultSensitivity = (e) => {
        setdefaultSensitivity(e.value);
    }

    const changepreserveExisting = (e) => {
        setpreserveExisting(e.value);
    }

    const propscheckChange = (PhraseSectionTypeId) => {
        PhraseSections.forEach(x => {
            if (x.PhraseSectionTypeId === PhraseSectionTypeId)
                x.ShowInDetails = !x.ShowInDetails;
        });

        setPhraseSections(PhraseSections);
    };

    const handleSubmission = () => {
        let order = 1;
        let customerColumns = [];
        for (var i = 0; i < materialColumns.length; i++) {
            if (materialColumns[i].customerColumns.length === 0) {
                materialColumns[i].customerColumns.push({ ColumnId: materialColumns[i].columnId, columnLocalizations: [], customerColumnId: 0, Selected: materialColumns[i].alwaysShow });
            }
            if (materialColumns[i].customerColumns.length > 0) {
                materialColumns[i].customerColumns[0].ColumnOrder = order;
                order++;
                let columnLocalizations = [];
                for (var j = 0; j < materialColumns[i].customerColumns[0].columnLocalizations.length; j++) {
                    if (materialColumns[i].customerColumns[0].columnLocalizations[j].columnLocalizationId < 0)
                        materialColumns[i].customerColumns[0].columnLocalizations[j].columnLocalizationId = 0;

                    if (materialColumns[i].customerColumns[0].columnLocalizations[j].localization)
                        columnLocalizations.push(materialColumns[i].customerColumns[0].columnLocalizations[j]);
                }

                materialColumns[i].customerColumns[0].columnLocalizations = columnLocalizations;
                materialColumns[i].customerColumns[0].width = materialColumns[i].width;
                customerColumns.push(materialColumns[i].customerColumns[0]);
            }
        }
        order = 1;
        for (var i = 0; i < documentColumns.length; i++) {
            if (documentColumns[i].customerColumns.length === 0) {
                documentColumns[i].customerColumns.push({ ColumnId: documentColumns[i].columnId, columnLocalizations: [], customerColumnId: 0, Selected: materialColumns[i].alwaysShow });
            }
            if (documentColumns[i].customerColumns.length > 0) {
                documentColumns[i].customerColumns[0].ColumnOrder = order;
                order++;

                for (var j = 0; j < documentColumns[i].customerColumns[0].columnLocalizations.length; j++) {
                    if (documentColumns[i].customerColumns[0].columnLocalizations[j].columnLocalizationId < 0)
                        documentColumns[i].customerColumns[0].columnLocalizations[j].columnLocalizationId = 0;
                }

                documentColumns[i].customerColumns[0].width = documentColumns[i].width;
                customerColumns.push(documentColumns[i].customerColumns[0]);
            }
        }

        order = 1;
        PhraseSections.forEach(x => {
            x.children = null;
            x.SortOrder = order++;
            x.ShowInDetails = x.ShowInDetails ? 1 : 0;
        });
        setPhraseSections(PhraseSections);

        setPending(true);
        setdisableSave(true);

        let postData = {
            CustomerName: CustomerName,
            TimezoneId: TimezoneId ?? '',
            DateFormatId: DateFormatId ?? '',
            customerColumns: customerColumns,
            formType: defaultIngredientType,
            docSense: defaultSensitivity,
            preserveExisting: preserveExisting ? 1 : 0,
            PhraseSections: PhraseSections
        };

        execute("POST", apiRoutes.users.settings.save, postData)
            .then((json) => {
                if (json != null) {
                    setPending(false);
                    setdisableSave(false);
                    let cust = props.customer;
                    cust.CustomerName = CustomerName;
                    cust.TimezoneId = TimezoneId;
                    cust.DateFormatId = DateFormatId;
                    cust.IadefaultFormulaType = defaultIngredientType;
                    cust.DefaultSensitivity = defaultSensitivity;
                    cust.PreserveExistingSensitivities = preserveExisting ? 1 : 0;
                    cust.PhraseSections = PhraseSections;
                    props.setcustomer(cust);
                    setsuccess(true);
                    setTimeout(function () {
                        setsuccess(false);
                    }, 5000);
                }
            })
            .catch((err) => {
                seterrorMessage(err);
                seterror(true);
            });
    };

    React.useEffect(() => {
        let cust = {};
        try {
            if (props.customer.CustomerId != null)
                cust = JSON.parse(JSON.stringify(props.customer));
        } catch { }

        setTimezoneId(cust.TimezoneId);
        setCustomerName(cust.CustomerName);
        setDateFormatId(cust.DateFormatId);
        setdefaultIngredientType(cust.IadefaultFormulaType);
        setdefaultSensitivity(cust.DefaultSensitivity);
        setpreserveExisting(cust.PreserveExistingSensitivities === 1);
        setdisplayDate(formatDate(dateNow, cust.DateFormatId));
    }, [props.customer]);

    const getData = () => {
        execute("GET",
            `${apiRoutes.column.GetColumns}`).then(async (response) => {
                if (response != null) {
                    let matColumns = [];
                    let docColumns = [];
                    for (var i = 0; i < response.length; i++) {
                        let title = response[i].columnPropertyValues.find(x => x.columnPropertyId === 1).columnPropertyValue1;
                        response[i].title = title;
                        let alwaysShow = response[i].columnPropertyValues.find(x => x.columnPropertyId === 4);
                        response[i].alwaysShow = alwaysShow != null && alwaysShow.columnPropertyValue1 === "true";

                        if (response[i].customerColumns.length > 0) {
                            response[i].order = response[i].customerColumns[0].columnOrder;
                            response[i].width = response[i].customerColumns[0].width;
                        }

                        if (!response[i].width)
                            response[i].width = 200;
                        if (response[i].gridId === 1)
                            matColumns.push(response[i]);
                        else
                            docColumns.push(response[i]);
                    }
                    setmaterialColumns(matColumns.sort((a, b) => a.order - b.order));
                    setdocumentColumns(docColumns.sort((a, b) => a.order - b.order));
                }
            });
        if (languages.length === 0) {
            fetch(apiRoutes.documents.language.options, { method: "GET" }).then(async (response) => {
                setlanguages(await response.json());
            });
        }
    }

    const [gotData, setgotData] = React.useState(false);
    React.useEffect(() => {
        if ((props.localizations.CorporateSettings == null && materialColumns.length > 0) || gotData)
            return;

        if (props.currentTenent.CustomerId != null && !props.currentTenent.IsEditConfigUser)
            navigate("/");
        setgotData(true);
    }, [execute]);

    React.useEffect(() => {
        if (gotData)
            getData();
    }, [gotData]);

    const insertItem = (item) => {
        item.LocalizationId = 0;
        setnewId(newId - 1);
        item.inEdit = false;
        return selectedColumn.customerColumns[0].columnLocalizations;
    };
    const updateItem = (item) => {
        let index = selectedColumn.customerColumns[0].columnLocalizations.findIndex((record) => record.columnLocalizationId === item.columnLocalizationId);
        selectedColumn.customerColumns[0].columnLocalizations[index] = item;
        return selectedColumn.customerColumns[0].columnLocalizations;
    };
    const deleteItem = (item) => {
        let index = selectedColumn.customerColumns[0].columnLocalizations.findIndex((record) => record.columnLocalizationId === item.columnLocalizationId);
        selectedColumn.customerColumns[0].columnLocalizations.splice(index, 1);
        return selectedColumn.customerColumns[0].columnLocalizations;
    };

    const remove = (dataItem) => {
        const newData = deleteItem(dataItem);
        selectedColumn.customerColumns[0].columnLocalizations = newData;
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };
    const add = (dataItem) => {
        dataItem.inEdit = true;
        const newData = insertItem(dataItem);
        selectedColumn.customerColumns[0].columnLocalizations = newData;
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };
    const update = (dataItem) => {
        dataItem.inEdit = false;
        dataItem.isNew = false;
        const newData = updateItem(dataItem);
        selectedColumn.customerColumns[0].columnLocalizations = newData;
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };

    // Local state operations
    const discard = (dataItem) => {
        let newData = selectedColumn.customerColumns[0].columnLocalizations;
        const index = selectedColumn.customerColumns[0].columnLocalizations.findIndex(x => x.columnLocalizationId === dataItem.columnLocalizationId);
        newData.splice(index, 1);
        selectedColumn.customerColumns[0].columnLocalizations = newData;
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };
    const cancel = (dataItem) => {
        const originalItem = selectedColumn.customerColumns[0].columnLocalizations.find(
            (p) => p.columnLocalizationId === dataItem.columnLocalizationId
        );
        originalItem.isEdit = false;
        const newcolumnLocalizations = selectedColumn.customerColumns[0].columnLocalizations.map((item) =>
            item.columnLocalizationId === originalItem.columnLocalizationId ? originalItem : item
        );
        selectedColumn.customerColumns[0].columnLocalizations = newcolumnLocalizations;
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };
    const enterEdit = (dataItem) => {
        const newcolumnLocalizations = selectedColumn.customerColumns[0].columnLocalizations.map((item) =>
            item.columnLocalizationId === dataItem.columnLocalizationId
                ? {
                    ...item,
                    inEdit: true,
                }
                : item
        );
        selectedColumn.customerColumns[0].columnLocalizations = newcolumnLocalizations;
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };
    const itemChange = (event) => {
        const field = event.field || "";
        const newcolumnLocalizations = selectedColumn.customerColumns[0].columnLocalizations.map((item) =>
            item.columnLocalizationId === event.dataItem.columnLocalizationId
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        selectedColumn.customerColumns[0].columnLocalizations = newcolumnLocalizations;
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };
    const addNew = () => {
        const newDataItem = {
            inEdit: true,
            isNew: true,
            localeId: 0,
            columnLocalizationId: newId
        };
        setnewId(newId - 1);
        selectedColumn.customerColumns[0].columnLocalizations.push(newDataItem);
        setselectedColumn(selectedColumn);
        setlocalizationsDataResult(process(selectedColumn.customerColumns[0].columnLocalizations, localizationsDataState));
    };

    return (
        <Layout
            versionNumber={props.versionNumber}
            currentTenent={props.currentTenent}
            success={success}
            setSuccess={setsuccess}
            error={error}
            setError={seterror}
            errorMessage={errorMessage}
            localizations={props.localizations}
            changeLanguage={props.changeLanguage}
            languageValue={props.languageValue}
        >
            <br />

            <Card className="dsCard k-card">
                <CardHeader
                    className="dsCardHeader"
                    onClick={corporateSettingsOpenChange}
                >
                    {props.localizations.CorporateSettings}
                </CardHeader>
                <Collapse isOpen={corporateSettingsOpen}>
                    <CardBody>
                        <span>{props.localizations.CompanyName}</span>
                        <SpheraInput
                            title="CompanyName"
                            name="CompanyName"
                            required
                            type="text"
                            placeholder={props.localizations.CompanyName}
                            onChange={CompanyNameChange}
                            value={CustomerName}
                        />
                        <hr className="dividerLine" />

                        <span>{props.localizations.Timezone}</span>
                        <SpheraDropdownEx
                            options={timezones}
                            value={timezones.find((x) => x.timezoneId === TimezoneId)}
                            textField="value"
                            dataItemKey="timezoneId"
                            onChange={handleTimezoneChange}
                            width="300px"
                            filterable
                        />

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <span>{props.localizations.DateFormat}</span>
                                        <SpheraDropdownEx
                                            options={dateFormats}
                                            value={dateFormats.find((x) => x.value === DateFormatId)}
                                            textField="text"
                                            dataItemKey="value"
                                            onChange={handleFormatChange}
                                            width="300px"
                                        />
                                    </td>
                                    <td className="previewCell">
                                        {displayDate == null || displayDate === "" ? "" : <div>{props.localizations.Preview}</div>}
                                        <span>{displayDate}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr className="dividerLine" />
                        <h3>{props.localizations.MaterialColumns}</h3>
                        <div className="row">
                            <div className="col-4">
                                <span><b>{props.localizations.Column}</b></span>
                            </div>
                            <div className="col-2">
                                <span><b>{props.localizations.Include}</b></span>
                            </div>
                            <div className="col-4">
                                <span><b>{props.localizations.Alias}</b></span>
                            </div>
                            <div className="col-2 widthButtonHeader">
                                <SpheraButtonEx
                                    text={props.localizations.SetColumnWidths}
                                    className="SetWidthsBtn btn"
                                    onClick={() => toggleWidthsModal(true)}
                                />
                            </div>
                        </div>
                        <div className="sortable">
                            <Sortable idField={'field'} data={materialColumns} itemUI=
                                {(props2) => (
                                    <SortableMaterialColumn {...props2} localizations={props.localizations} includecustomcolumn1Change={includecustomcolumn1Change} currentLocaleId={props.languageValue.localeId}
                                        toggleCustomColumn={toggleCustomColumn} customcolumn1Change={customcolumn1Change} isSettings={true} />
                                )}
                                onDragOver={onDragOver} onNavigate={onNavigate} />
                        </div>
                        <h3>{props.localizations.DocumentColumns}</h3>
                        <div className="row">
                            <div className="col-4">
                                <span><b>{props.localizations.Column}</b></span>
                            </div>
                            <div className="col-2">
                                <span><b>{props.localizations.Include}</b></span>
                            </div>
                            <div className="col-4">
                                <span><b>{props.localizations.Alias}</b></span>
                            </div>
                            <div className="col-2 widthButtonHeader">
                                <SpheraButtonEx
                                    text="Set Column Widths"
                                    className="SetWidthsBtn btn"
                                    onClick={() => toggleWidthsModal(false)}
                                />
                            </div>
                        </div>
                        <div className="sortable">
                            <Sortable idField={'field'} data={documentColumns} itemUI=
                                {(props2) => (
                                    <SortableMaterialColumn {...props2} localizations={props.localizations} includecustomcolumn1Change={includecustomcolumn1Change} currentLocaleId={props.languageValue.localeId}
                                        toggleCustomColumn={toggleCustomColumn} customcolumn1Change={customcolumn1Change} isSettings={true} />
                                )}
                                onDragOver={docOnDragOver} onNavigate={docOnNavigate} />
                        </div>

                        <hr className="dividerLine" />
                        <h3>{props.localizations.PublishSettings}</h3>
                        <div className="row">
                            <div className="col-6">
                                <SpheraRadioButtonGroup data={[{ label: props.localizations.InternalProprietary, value: 1 },
                                { label: props.localizations.Public, value: 2 }]} label={props.localizations.DefaultSensitivity} value={defaultSensitivity} onChange={changedefaultSensitivity} direction="vertical" />
                                <SpheraCheckbox label={props.localizations.PreserveExisting} primary={true} value={preserveExisting} onChange={changepreserveExisting} />
                            </div>
                            <div className="col-6">
                                <SpheraRadioButtonGroup data={[{ label: props.localizations.Standard, value: 0 },
                                { label: props.localizations.FullFormula, value: 1 }]} label={props.localizations.DefaultIngredientType} value={defaultIngredientType} onChange={changedefaultIngredientType} direction="vertical" />
                            </div>
                        </div>

                        <hr className="dividerLine" />
                        <h3>{props.localizations.PropertyDisplaySettings}</h3>
                        <div className="row">
                            <div className="col-5">
                                <span><b>{props.localizations.Section}</b></span>
                            </div>
                            <div className="col-2">
                                <span><b>{props.localizations.Include}</b></span>
                            </div>
                        </div>
                        <div className="sortable">
                            <Sortable idField={'PhraseSectionTypeId'} data={PhraseSections}
                                itemUI={(props2) => (
                                    <SortableCustomerProperty {...props2} localizations={props.localizations} propscheckChange={propscheckChange} />
                                )}
                                onDragOver={propOnDragOver} onNavigate={propOnNavigate} />
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
            {pending && (
                <LoadingPanel localizations={props.localizations} />
            )}

            <SpheraButtonEx
                text={props.localizations.Save}
                className="configSaveBtn btn-primary mt-3 float-right mb-3 btn-primary"
                onClick={handleSubmission}
                disabled={disableSave}
            />
            <CustomColumnLocalizationModal languages={languages} selectedColumn={selectedColumn} showCustomColumnModal={showCustomColumnModal}
                toggleCustomColumn={toggleCustomColumn} localizations={props.localizations} enterEdit={enterEdit} remove={remove} add={add}
                discard={discard} update={update} cancel={cancel} itemChange={itemChange} addNew={addNew}
                dataState={localizationsDataState} dataResult={localizationsDataResult} dataStateChange={localizationsDataStateChange} />
            <CustomColumnWidthsModal localizations={props.localizations} toggleWidthsModal={toggleWidthsModal} showWidthModal={showWidthModal}
                materialColumns={materialColumns} documentColumns={documentColumns} showMaterial={showMaterial} showDetails={showDetails}
                setmaterialColumns={setmaterialColumns} setdocumentColumns={setdocumentColumns} />
        </Layout>
    );
}
