import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { process } from "@progress/kendo-data-query";
import { Layout } from '../components/MasterPage/Layout';
import { DocumentGrid } from '../components/Grid/DocumentGrid';
import { DocumentSeachBox } from '../components/DocumentSearchBox';
import { DateFormater } from "../services/DateFormater"
import useFetchWithMsal from '../services/useFetchWithMsal';
import { apiRoutes } from '../services/apiRoutes';
import { cookieHandler } from "../services/cookieHandler";

export function Home(props) {
	const [materialColumns, setmaterialColumns] = React.useState([]);
	const [ColumnsOriginal, setColumnsOriginal] = React.useState([]);
	const { adjustForTimezone } = DateFormater();
	const { getCookie } = cookieHandler();
	const [preSearch, setpreSearch] = React.useState(true);

	let documentGridSettingsJSON = getCookie("SpheraSearchType");
	let searchTypetemp = "1";
	if (documentGridSettingsJSON != null && documentGridSettingsJSON !== "") {
		searchTypetemp = documentGridSettingsJSON;
	}

	const urlParams = new URLSearchParams(window.location.search);
	let sArch = urlParams.get('searchArchive');
	if (sArch == null || !props.currentTenent.IsViewArchivedDocumentsUser)
		sArch = false;
	const [searchArchive, setsearchArchive] = React.useState(sArch); 
	const [searchType, setsearchType] = React.useState(searchTypetemp); 
	const [searchPhrase, setsearchPhrase] = React.useState("");
	const [searchPhraseNoWildcard, setsearchPhraseNoWildcard] = React.useState("");
	const [searchedPhrase, setsearchedPhrase] = React.useState("");
	const [documents, setdocuments] = React.useState([]);
	const [dataState, setDataState] = React.useState({ skip: 0, take: 25 });
	const [pending, setPending] = React.useState(false);
	const[dataResult, setDataResult] = React.useState(
		process(documents, dataState)
		);
	const dataStateChange = (event) => {
		setDataResult(process(documents, event.dataState));
		setDataState(event.dataState);
	};
	const [showLanguageFilter, setshowLanguageFilter] = React.useState(false);
	const [languageFilterLoaded, setlanguageFilterLoaded] = React.useState(false);
	const [localesSelected, setlocalesSelected] = React.useState([]);
	const [localesDropdownData, setlocalesDropdownData] = React.useState([]);

	const handleLocalesDropdownChange = (e) => {
		localesDropdownData.forEach(x => {
			x.show = false;
		});
		e.value.forEach(x => {
			let phrase = localesDropdownData.find(y => x.value === y.value);
			if (phrase)
				phrase.show = true;
		});

		let locales = e.value;
		setlocalesSelected(locales);
		setlocalesDropdownData(localesDropdownData);
	};

	const { execute } = useFetchWithMsal({
		scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
	});

	const changeLanguage = () => {
		let newDocs = documents;
		newDocs = newDocs.map(x => {
			x.materialName = getCorrectLocalization(x.synonyms, props.languageValue.languageCode, false);
			x.manufacturer = getCorrectLocalization(x.synonyms, props.languageValue.languageCode, true) ?? x.manufacturer;
			return x;
		});

		setdocuments(newDocs);
		setDataResult(process(newDocs, dataState));

		processColumns(ColumnsOriginal, props.languageValue.localeId);
	}

	const getCorrectLocalization = (synonyms, cultureInfoCode, IsMfgName) => {
		let synType = 2;
		if (IsMfgName)
			synType = 4;

		if (synonyms.length === 0)
			return "";

		let localization = synonyms.find(x => x.cultureInfoCode === cultureInfoCode && x.synonymType === synType);
		if (localization != null)
			return localization.synonym;

		let languageSection = (cultureInfoCode.indexOf("-") !== -1) ? cultureInfoCode.substring(0, cultureInfoCode.indexOf("-") + 1) : cultureInfoCode;
		localization = synonyms.find(x => x.cultureInfoCode.indexOf(languageSection) !== -1 && x.synonymType === synType);
		if (localization != null)
			return localization.synonym;

		localization = synonyms.find(x => x.cultureInfoCode.indexOf("en-") !== -1 && x.synonymType === synType);
		if (localization != null)
			return localization.synonym;

		localization = synonyms.find(x => x.synonymType === synType);
		if (localization != null)
			return localization.synonym;

		if (IsMfgName)
			return null

		return synonyms[0].synonym;
	}

	const [gotData, setgotData] = React.useState(false);
	React.useEffect(() => {
		if ((props.localizations.Search == null || gotData) && execute != null)
			return;
		setgotData(true);
	}, [execute, props.localizations]);

	React.useEffect(() => { changeLanguage(); }, [props.localizations]);

	React.useEffect(() => {
		if (gotData) {
			execute("GET",
				`${apiRoutes.column.GetColumns}`).then(async (response) => {
					if (response != null) {
						processColumns(response, props.languageValue.localeId);
					}
				});

			const urlParams = new URLSearchParams(window.location.search);
			let searchPhrase = urlParams.get('search');
			if (searchPhrase != null) {
				setsearchPhrase(searchPhrase);
				setsearchPhraseNoWildcard(searchPhrase.replaceAll("?", "").replaceAll("*", ""));
				setsearchedPhrase(searchPhrase);
				SubmitSearch(execute, searchPhrase, searchPhrase.replaceAll("?", "").replaceAll("*", ""), localStorage.getItem("languages"), sArch);
			}


			execute("GET", apiRoutes.documents.languages).then(
				(json) => {
					if (json != null) {
						for (var i = 0; i < json.length; i++) {
							json[i].label = (props.localizations[json[i].languageName] ?? json[i].languageName) + " - " + json[i].country;
							json[i].value = json[i].languageId;
						}
						setlocalesDropdownData(json);

						let values = "";
						if (localStorage.getItem("languages")) {
							let ids = localStorage.getItem("languages").split(",");
							values = json.filter(x => ids.filter(y => y == x.languageId).length > 0);
							setlocalesSelected(values);
						}
					}
				}
			);
		}
	}, [gotData]);

	React.useEffect(() => {
		if (!languageFilterLoaded && localesDropdownData.length > 0 && props.currentTenent) {
			if (props.currentTenent.UserSearchMaterialFilter && props.currentTenent.UserSearchMaterialFilter.LanguagesIds && props.currentTenent.UserSearchMaterialFilter.LanguagesIds.length != 0)
			{
				let newdata = localesDropdownData.filter(x => props.currentTenent.UserSearchMaterialFilter.LanguagesIds.filter(y => y == x.value).length != 0);
				setlocalesDropdownData(newdata);
				if (localStorage.getItem("languages")) {
					let ids = localStorage.getItem("languages").split(",");
					let values = newdata.filter(x => ids.filter(y => y == x.languageId).length > 0);
					setlocalesSelected(values);
				}
			}
			setlanguageFilterLoaded(true);
		}

		if (props.customer.path && preSearch)
			setshowLanguageFilter(true);
	}, [props.currentTenent, localesDropdownData]);

	const processColumns = (columns, langugeId) => {
		let count = 0;
		let newOrder = 99;
		let matColumns = [];
		for (var i = 0; i < columns.length; i++) {
			if (columns[i].gridId === 1) {
				let newCol = {};
				newCol.width = 200;
				newCol.field = columns[i].field;
				for (var j = 0; j < columns[i].columnPropertyValues.length; j++) {
					newCol[columns[i].columnPropertyValues[j].columnProperty.columnProperty1.trim()] = columns[i].columnPropertyValues[j].columnPropertyValue1.trim();
				}

				if (columns[i].customerColumns[0] != null) {
					count++;
					newCol.show = columns[i].customerColumns[0].selected;
					newCol.order = columns[i].customerColumns[0].columnOrder;
					newCol.width = columns[i].customerColumns[0].width;
				}
				else {
					newCol.show = false;
					newCol.order = newOrder;
					newOrder++;
				}

				if (columns[i].customerColumns.length > 0) {
					let used = false;
					for (var j = 0; j < columns[i].customerColumns[0].columnLocalizations.length; j++) {
						if (langugeId == columns[i].customerColumns[0].columnLocalizations[j].localeId && columns[i].customerColumns[0].columnLocalizations[j].localization) {
							newCol.Title = columns[i].customerColumns[0].columnLocalizations[j].localization;
							used = true;
						}
					}

					if (!used || !newCol.Title) {
						let enLoc = columns[i].customerColumns[0].columnLocalizations.find(x => x.localeId == 1033);
						if (enLoc != null && enLoc.localization)
							newCol.Title = enLoc.localization;
						else if (columns[i].customerColumns[0].columnLocalizations.length > 0 && columns[i].customerColumns[0].columnLocalizations[0].localization)
							newCol.Title = columns[i].customerColumns[0].columnLocalizations[0].localization;
						else
							newCol.Title = props.localizations[newCol.Title];
					}
					if (!newCol.Title)
						newCol.Title = columns[i].field;
				}

				matColumns.push(newCol);
			}
		}
		if (count == 0) {
			for (var i = 0; i < matColumns.length; i++) {
				matColumns[i].show = true;
				matColumns[i].order = i;
			}
		}
		setmaterialColumns(matColumns.sort((a, b) => a.order - b.order));
		setColumnsOriginal(columns);
	}

	const SubmitSearch = (execute, searchPhraseCookie, searchPhraseNoWildcardCookie, languageValues, searchArch) => {
		let searchPhraseVal = searchPhrase;
		let searchPhraseNoWildcardCheck = searchPhraseNoWildcard;
		if (searchPhraseNoWildcardCookie != null)
			searchPhraseNoWildcardCheck = searchPhraseNoWildcardCookie;
		if (searchPhraseCookie != null)
			searchPhraseVal = searchPhraseCookie;

		if (searchPhraseNoWildcardCheck == null || searchPhraseNoWildcardCheck.length < 3)
			return;

		let languageIds = [];
		for (var i = 0; i < localesSelected.length; i++) {
			languageIds.push(localesSelected[i].languageId);
		}
		languageIds = languageIds.join(",");

		if (languageValues)
			languageIds = languageValues;

		localStorage.setItem("languages", languageIds);

		if (searchArchive || searchArch)
			searchArch = true;

		setpreSearch(false);
		setPending(true);
		execute("GET", `${apiRoutes.materials.search}?searchPhrase=${encodeURI(searchPhraseVal)}&phrase=${(searchType === "2")}&languageIds=${localStorage.getItem("languages")}${searchArch ? "&searchArchive=true" : ""}`).then((response) => {
			if (response != null) {
				let documents = response.map(x => {
					if (x.timestamp_Create != null) {
						x.timestamp_CreateDisplay = adjustForTimezone(x.timestamp_Create.substring(0, x.timestamp_Create.indexOf("Z")), props.customer.TimezoneId);
						x.timestamp_Create = new Date(adjustForTimezone(x.timestamp_Create.substring(0, x.timestamp_Create.indexOf("Z")), props.customer.TimezoneId).toDateString());
					}
					if (x.timestamp_Modified != null) {
						x.timestamp_ModifiedDisplay = adjustForTimezone(x.timestamp_Modified.substring(0, x.timestamp_Modified.indexOf("Z")), props.customer.TimezoneId);
						x.timestamp_Modified = new Date(adjustForTimezone(x.timestamp_Modified.substring(0, x.timestamp_Modified.indexOf("Z")), props.customer.TimezoneId).toDateString());
					}

					x.materialName = getCorrectLocalization(x.synonyms, props.languageValue.languageCode, false);
					x.manufacturer = getCorrectLocalization(x.synonyms, props.languageValue.languageCode, true) ?? x.manufacturer;

					x.materialCodesText = x.materialCodes.join(', ');
					x.materialTags.sort((a, b) => a.tag.localeCompare(b.tag));

					if (x.casNumber != null) {
						let length = x.casNumber.length;
						if (x.casNumber.indexOf("-") === -1)
							x.casNumber = x.casNumber.slice(0, length - 3) + "-" + x.casNumber.slice(length - 3, length - 1) + "-" + x.casNumber.slice(length - 1);
					}

					x.materialTagsText = ReactDOMServer.renderToStaticMarkup(<div className="gridRowTags">{x.materialTags.map((y, index) =>
						<div className="sphera-chip" key={index + "matTag"} >
							<div className="k-chip k-selected k-focus k-chip-md k-rounded-md k-chip-solid k-chip-solid-base chip-purple s-chip">
								<span className="k-chip-content">
									<span className="k-chip-label">{y.tag}</span>
								</span>
							</div>
						</div>)}</div>);

					return x;
				});
				setdocuments(documents);
				setDataResult(process(documents, { skip: 0, take: 25 }));
				setDataState({ skip: 0, take: 25 });
				setPending(false);
				setsearchedPhrase(searchPhraseVal);
				return;
			}
		});
	}

	return (
		<Layout searchArchive={searchArchive} versionNumber={props.versionNumber} currentTenent={props.currentTenent} localizations={props.localizations} changeLanguage={changeLanguage} languageValue={props.languageValue}>
			<DocumentSeachBox currentTenent={props.currentTenent} searchPhraseNoWildcard={searchPhraseNoWildcard} setsearchPhraseNoWildcard={setsearchPhraseNoWildcard}
				loginConfig={props.loginConfig} setsearchType={setsearchType} searchType={searchType} localizations={props.localizations}
				SubmitSearch={SubmitSearch} setsearchPhrase={setsearchPhrase} searchPhrase={searchPhrase} preSearch={preSearch} searchArchive={searchArchive} setsearchArchive={setsearchArchive}
				localesSelected={localesSelected} handleLocalesDropdownChange={handleLocalesDropdownChange} localesDropdownData={localesDropdownData} ></DocumentSeachBox>
			{!preSearch && <DocumentGrid
				materialColumns={materialColumns}
				searchedPhrase={searchedPhrase}
				customer={props.customer}
				dateFormatId={props.customer.dateFormatId}
				pending={pending}
				localizations={props.localizations}
				documents={documents}
				dataState={dataState}
				dataResult={dataResult}
				dataStateChange={dataStateChange}
				currentTenent={props.currentTenent}
				searchArchive={searchArchive}
			>
			</DocumentGrid>}
		</Layout>
	);
}
