import React from 'react';
import { SpheraModal } from '@sphera-cloud/component-ui-reactjs';
import { process } from "@progress/kendo-data-query";
import { GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import MaterialSynoymsGridColumns from "../../Data/MaterialSynoymsGridColumns.json";
import { CustomColumnMenu } from "../CustomColumnMenu";
import { ColumnManager } from "../../services/columnManager";
import { TextCell } from '../Grid/Cell/TextCell';

export function MaterialSynoymsModal(props) {
    const [dataState, setDataState] = React.useState({ skip: 0, take: 25 });
    const [dataResult, setDataResult] = React.useState(
        process(props.synonyms, dataState)
    );
    const dataStateChange = (event) => {
        setDataResult(process(props.synonyms, event.dataState));
        setDataState(event.dataState);
    };

    const { columnResize, columnReorder, onColumnsSubmit, defaultSettings } = ColumnManager(MaterialSynoymsGridColumns, "MaterialSynonymGridSettings");
    const [stateColumns, setStateColumns] = React.useState(defaultSettings);

    React.useEffect(() => {
        setDataResult(process(props.synonyms, dataState));
    }, [props.synonyms]);

    return (
        <div>
            {props.showSynsModal && (
                <SpheraModal className="MaterialSynoymsModal" title={props.localizations.Synonyms} width={700} height="440px" onClose={props.toggleSyns} noFooter={true} >
                    <div >
                        <Grid
                            className="sphera-grid"
                            style={{
                                height: '330px',
                                width: '95%',
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "10px",
                                maxHeight: '380px'
                            }}
                            data={dataResult}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            sortable={true}
                            reorderable={true}
                            resizable={true}
                            scrollable="scrollable"
                            onColumnReorder={columnReorder}
                            onColumnResize={columnResize}
                            pageable={{
                                buttonCount: 4,
                                pageSizes: [25, 50, 100]
                            }}
                        >
                            {stateColumns.map(
                                (column, idx) =>
                                    column.show && (
                                        <Column
                                            key={idx}
                                            cell={(props2) =>
                                                <TextCell field={column.field}
                                                    isLink={column.isLink}
                                                    searchedPhrase={""}
                                                    linkClick={null}
                                                    clickId={props2.dataItem.materialId}
                                                    DisplayValue={column.adjustTimezone ? props2.dataItem[column.field + "Display"] : props2.dataItem[column.field]}
                                                    customer={props.customer}
                                                    isDate={column.isDate}
                                                    adjustTimezone={column.adjustTimezone}
                                                />
                                            }
                                            field={column.field}
                                            width={column.width}
                                            minResizableWidth={100}
                                            title={props.localizations[column.title]}
                                            filter={column.filter}
                                            format={null}
                                            headerClassName={GridColumnMenuFilter.active(column.field, dataState.filter) ? 'active' : ''}
                                            columnMenu={(props2) => (
                                                <CustomColumnMenu {...props2} data={props.synonyms} checkboxFilter={column.checkboxFilter} columns={MaterialSynoymsGridColumns} useDateFilter={column.field === "revisionDate"} dateFormatFilter={null} />
                                            )}
                                        />
                                    )
                            )}
                        </Grid>
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}