import { Home } from "../pages/Home";
import { Settings } from "../pages/Settings";
import { ManageSites } from "../pages/ManageSites";
import { Logout } from "../pages/Logout";
import { DocumentDetails } from "../pages/DocumentDetails";
import { UserList } from "../pages/UserList";
import { ManageUser } from "../pages/ManageUser";
import { ManageCustomers } from "../pages/ManageCustomers";
import { History } from "../pages/History";
import { ThirdPartyNotice } from "../components/ThirdPartyNotice";
import { Distribution } from "../pages/Distribution";
import { Widgets } from "../pages/Widgets";
import { PublicSite } from "../pages/PublicSite";
import { WidgetDesigner } from "../components/Widget/WidgetDesigner";
import { Filters } from "../pages/Filters"

export const routes = [
    {
        title: "",
        path: "/material/:id",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "Material",
                keyName: "Material",
                link: "/material/",
            },
        ],
        type: DocumentDetails,
    },
    {
        title: "ManageIaUsers",
        path: "/manageUsers",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "ManageIaUsers",
                keyName: "ManageIaUsers",
                link: "/manageUsers",
            },
        ],
        type: UserList,
    },
    {
        title: "ManageIaUser",
        path: "/manageUser/:id",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "ManageIaUsers",
                keyName: "ManageIaUsers",
                link: "/manageUsers",
            },
            {
                id: "level-03",
                text: "ManageIaUser",
                keyName: "ManageIaUser",
                link: "/manageUser/",
            },
        ],
        type: ManageUser,
    },
    {
        title: "ReviewHistory",
        path: "/reviewHistory",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "ReviewHistory",
                keyName: "ReviewHistory",
                link: "/reviewHistory",
            },
        ],
        type: History,
    },
    {
        title: "Config",
        path: "/config",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "Config",
                keyName: "Config",
                link: "/config",
            },
        ],
        type: Settings,
    },
    {
        title: "Filters",
        path: "/filters",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "Filters",
                keyName: "Filters",
                link: "/filters",
            },
        ],
        type: Filters,
    },
    {
        title: "ManageCustomers",
        path: "/manageCustomers",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "ManageCustomers",
                keyName: "ManageCustomers",
                link: "/manageCustomers",
            },
        ],
        type: ManageCustomers,
    },
    {
        title: "CustomerTokens",
        path: "/customerTokens",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "Customer Tokens",
                keyName: "CustomerTokens",
                link: "/customerTokens",
            },
        ],
        type: Distribution,
    },
    {
        title: "CustomerPortal",
        path: "/customerPortal",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                iconClass: "icon-ui-general-home",
                link: "/",
            },
            {
                id: "level-02",
                text: "CustomerPortal",
                keyName: "CustomerPortal",
                link: "/customerPortal"
            },
        ],
        type: PublicSite,
    },
    {
        title: "Widgets",
        path: "/widgets",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
            {
                id: "level-02",
                text: "Widgets",
                keyName: "Widgets",
                link: "/widgets",
            },
        ],
        type: Widgets,
    },
    //{
    //  title: "widget designer",
    //  path: "/widgets/:id",
    //  breadcrumbItems: [
    //    {
    //      id: "level-01",
    //      text: "Home",
    //      link: "/",
    //    },
    //    {
    //      id: "level-02",
    //      text: "widget designer",
    //      link: "/widgets/:id",
    //    },
    //  ],
    //  type: WidgetDesigner,
    //},
    {
        title: "ThirdPartyNotice",
        path: "/ThirdPartyNotice",
        type: ThirdPartyNotice,
    },

    //{
    //  "title": "manageSites",
    //  "path": "/manageSites",
    //  "breadcrumbItems": [
    //    {
    //      "id": "level-01",
    //      "text": "Home",
    //      "iconClass": "icon-ui-general-home",
    //      "link": "/"
    //    },
    //    {
    //      "id": "level-02",
    //      "text": "ManageSites",
    //      "iconClass": "icon-ui-general-hierarchy",
    //      "link": "/manageSites"
    //    }
    //  ],
    //  "type": ManageSites
    //},
    {
        title: "MaterialSearch",
        path: "/",
        breadcrumbItems: [
            {
                id: "level-01",
                text: "Home",
                keyName: "Home",
                link: "/",
            },
        ],
        type: Home,
    },
    {
        title: "logout",
        path: "/logout",
        breadcrumbItems: [],
        type: Logout,
    },
];
