import "bootstrap/dist/css/bootstrap.css";
import "@sphera-cloud/component-ui-reactjs/dist/assets/font/InterWeb/inter.css";
import './styles/documentStorageSearch.css';
import './styles/prism.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import { authConfig } from "./services/authConfig.js";
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { apiRoutes } from "./services/apiRoutes";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const configResponse = await (await fetch(apiRoutes.config.get, { method: "GET" })).json();
const config = new authConfig(configResponse);

const msalInstance = new PublicClientApplication(config.msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
    <BrowserRouter basename={baseUrl}>
        <MsalProvider instance={msalInstance}>
            <App instance={msalInstance} loginConfig={config} />
        </MsalProvider>
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
