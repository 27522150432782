import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { AppFooter } from './AppFooter.jsx';
import { SpheraNotificationGroup, SpheraNotification, SpheraHeader, SpheraBreadcrumb } from "@sphera-cloud/component-ui-reactjs";
import { useNavigate } from "react-router-dom";
import { routes } from "../../services/Routes.jsx";

export function Layout(props) {
    let navigate = useNavigate();
    let route = routes.find(x => window.location.pathname.indexOf(x.path.replace(":id", "")) > -1);

    route.breadcrumbItems[route.breadcrumbItems.length - 1].link = window.location.pathname;

    let title = JSON.parse(JSON.stringify(route.title));
    if (props.searchArchive)
        title = "ArchiveMaterialSearch";

    for (const crumb of route.breadcrumbItems) {
        crumb.text = props.localizations[crumb.keyName] ?? crumb.text;
    }

    let header = <SpheraHeader text={props.localizations[title]} breadcrumbData={route.breadcrumbItems} button={props.buttonsConfig} />;
    if (props.localizations[title] == null && props.localizations != null)
        header = <SpheraBreadcrumb items={route.breadcrumbItems} navigate={navigate} />;

    let MainContent =
        <Container fluid>
            <Row>
                <Col lg="12">
                    <div className='mb-3'>{header}</div>
                    {props.children}
                </Col>
            </Row>
        </Container>;


    return(
        <div>
            {MainContent}
            <SpheraNotificationGroup
                position={{
                    left: '45%',
                    bottom:'2.5rem',
                    alignItems: "flex-center",
                }}>
                {props.success && <SpheraNotification closable={true}
                    type="success"
                    theme="dark"
                    text={props.localizations.SaveSuccessful}
                    onClose={() => props.setSuccess(false)} />}
                {props.error && <SpheraNotification closable={true}
                    type="error"
                    theme="dark"
                    text={props.localizations.SaveError + ": " + props.errorMessage}
                    onClose={() => props.setError(false)} />}
                {props.info && <SpheraNotification closable={true}
                    type="info"
                    theme="dark"
                    text={props.infoMessage}
                    onClose={() => props.setInfo(false)} />}
                {props.warning && <SpheraNotification closable={true}
                    type="warning"
                    theme="dark"
                    text={props.localizations.Warning + ": " + props.warningMessage}
                    onClose={() => props.setWarning(false)} />}
            </SpheraNotificationGroup>
            <AppFooter localizations={props.localizations} versionNumber={props.versionNumber} />
        </div >
        );
}
