import { apiRoutes } from "../services/apiRoutes";
import { DateFormater } from "../services/DateFormater";

export const SiteData = (props) => {
    const { adjustForTimezone } = DateFormater();

    const getSites = async () => {
        let json = await props.execute("GET", apiRoutes.publicSites.get);
        let newEditableCards = [];
        json?.forEach((site, key) => 
            newEditableCards.push({ isEdit: false, value: site.id, label: site.name, site: site })
        );

        return newEditableCards;
    }

    return {
        getSites
    };
}