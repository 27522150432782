import React from 'react';
import { useNavigate } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { SpheraButtonEx, SpheraDropdownEx, SpheraCheckbox } from "@sphera-cloud/component-ui-reactjs";
import useFetchWithMsal from '../services/useFetchWithMsal';
import { apiRoutes } from '../services/apiRoutes';
import dateFormats from "../Data/dateFormats.json";
import { DateFormater } from "../services/DateFormater"
import { HistoryGrid } from '../components/Grid/HistoryGrid';
import { Layout } from '../components/MasterPage/Layout';

export function History(props) {
	let maxDate = new Date();
	if (maxDate.getUTCDate() !== maxDate.getDate())
		maxDate = new Date(maxDate.setDate(maxDate.getUTCDate()));

	let dateFormat = "dd/MM/yyyy";
	if (props.customer.DateFormatId != null) {
		let format = dateFormats.find((x) => x.value === props.customer.DateFormatId);
		if (format != null) {
			dateFormat = format.text;
		}
	}
	const statusTypes = [
		{
			status: props.localizations.Inactive,
			statusId: 0
		},
		{
			status: props.localizations.Active,
			statusId: 1
		},
		{
			status: props.localizations.Pending,
			statusId: 2
		},
		{
			status: props.localizations.Failed,
			statusId: 3
		},
		{
			status: props.localizations.Archived,
			statusId: 4
		}
	];

	const options = [
		{ value: 1, label: props.localizations.IncludeActive },
		{ value: 2, label: props.localizations.IncludeInactive },
		{ value: 3, label: props.localizations.IncludePending },
		{ value: 4, label: props.localizations.IncludeFailed },
	];

	const { adjustForTimezone } = DateFormater();
	let navigate = useNavigate();

	const [downloadGrid, setdownloadGrid] = React.useState(false);
	const [showColumnSelect, setshowColumnSelect] = React.useState(false);
	const [buttonsConfig, setbuttonsConfig] = React.useState([]);

	const [users, setusers] = React.useState([]);
	const [dataState, setDataState] = React.useState({ skip: 0, take: 25 });
	const [pending, setPending] = React.useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	let sArch = urlParams.get('searchArchive');
	if (sArch == null || !props.currentTenent.IsViewArchivedDocumentsUser)
		sArch = false;
	const [searchArchive, setsearchArchive] = React.useState(sArch); 

	const [includeActive, setincludeActive] = React.useState(true);
	const [includeInactive, setincludeInactive] = React.useState(false);
	const [includePending, setincludePending] = React.useState(false);
	const [includeFailed, setincludeFailed] = React.useState(false);
	const [disableSearch, setdisableSearch] = React.useState(false);
	const [showStatusDd, setshowStatusDd] = React.useState(true);
	const [startOptionValue, setstartOptionValue] = React.useState([]);

	const [startDate, setstartDate] = React.useState(new Date(new Date().getTime() - 604800000));
	const [endDate, setendDate] = React.useState(new Date());

	const handleincludeChange = (e) => {
		setstartOptionValue(e.value);
		setincludeActive(e.value.find(x => x.value === 1) != null);
		setincludeInactive(e.value.find(x => x.value === 2) != null);
		setincludePending(e.value.find(x => x.value === 3) != null);
		setincludeFailed(e.value.find(x => x.value === 4) != null);
		setdisableSearch(e.value.length === 0);
	}

	const changestartDate = (event) => { setstartDate(new Date(event.value)) };
	const changeendDate = (event) => { setendDate(new Date(event.value)) };

	const [dataResult, setDataResult] = React.useState(
		process(users, dataState)
	);
	const dataStateChange = (event) => {
		setDataResult(process(users, event.dataState));
		setDataState(event.dataState);
	};

	const { execute } = useFetchWithMsal({
		scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
	});

	const changeArchiveSearch = () => {
		setshowStatusDd(searchArchive);
		setsearchArchive(!searchArchive);
	}

	React.useEffect(() => {
		localStorage.removeItem("languages");
		if (props.localizations.IncludeActive != null) {
			setstartOptionValue([{ value: 1, label: props.localizations.IncludeActive }]);
			setbuttonsConfig([
				{
					type: 'secondary',
					icon: 'icon-ui-general-libraries',
					tooltip: props.localizations.Columns,
					position: "top",
					onClick: () => { setshowColumnSelect(true); }
				},
				{
					type: 'secondary',
					icon: 'icon-ui-general-download',
					tooltip: props.localizations.Download,
					position: "top",
					onClick: () => { setdownloadGrid(true); }
				}
			]);

			if (props.currentTenent.CustomerId != null && !props.currentTenent.IsReviewHistoryUser)
				navigate("/");
		}
	}, [props.localizations]);

	const getData = () => {
		let endDateWithTime = endDate.toLocaleDateString() + ",23:59:59";
		setPending(true);
		execute("GET", `${apiRoutes.documents.history}?periodBeginDate=` + startDate.toLocaleDateString() + `&periodEndDate=` + endDateWithTime + `&includeActive=` +
			includeActive + `&includeInactive=` + includeInactive + `&includePending=` + includePending + `&includeFailed=` + includeFailed + `&searchArchived=` + searchArchive)
			.then(json => {
				if (json != null) {
					json.forEach((doc) => {
						doc.statusText = statusTypes.find(x => x.statusId === doc.status).status;
						if (doc.status === 2 || doc.status === 3)
							doc.materialId = null;

						doc.languagesText = doc.languages.map((x) => x.languageName + "-" + x.country).join(', ');

						if (doc.regulatoryJurisdiction == null || doc.regulatoryJurisdiction === "")
							doc.regulatoryJurisdiction = props.localizations.Global;

						doc.revisionDate = new Date(new Date(doc.revisionDate).toDateString());

						doc.timestamp_CreateDisplay = adjustForTimezone(doc.timestamp_Create.substring(0, doc.timestamp_Create.indexOf("+")), props.customer.TimezoneId);
						doc.timestamp_Create = new Date(adjustForTimezone(doc.timestamp_Create.substring(0, doc.timestamp_Create.indexOf("+")), props.customer.TimezoneId).toDateString());
						if (doc.timestamp_Modified != null) {
							doc.timestamp_ModifiedDisplay = adjustForTimezone(doc.timestamp_Modified.substring(0, doc.timestamp_Modified.indexOf("+")), props.customer.TimezoneId);
							doc.timestamp_Modified = new Date(adjustForTimezone(doc.timestamp_Modified.substring(0, doc.timestamp_Modified.indexOf("+")), props.customer.TimezoneId).toDateString());
						}
					});
					setusers(json);

					setDataResult(process(json, { skip: 0, take: 25 }));
					setDataState({ skip: 0, take: 25 });
					setPending(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<Layout versionNumber={props.versionNumber} buttonsConfig={buttonsConfig} currentTenent={props.currentTenent} localizations={props.localizations} changeLanguage={props.changeLanguage} languageValue={props.languageValue} >
			<div>
				<label className="dsStartDateLabel">{props.localizations.Start}</label><label className="dsEndDateLabel">{props.localizations.End}</label>
			</div>
			<div className="dsCriteriaDiv" >
				<DatePicker
					className="dsStartDate"
					width={200}
					max={endDate}
					format={dateFormat}
					value={startDate}
					onChange={changestartDate}
					navigation={false}
				/>
				<DatePicker
					className="dsEndDate"
					width={200}
					min={startDate}
					max={maxDate}
					format={dateFormat}
					value={endDate}
					onChange={changeendDate}
					navigation={false}
				/>

				{props.currentTenent.IsViewArchivedDocumentsUser && <SpheraCheckbox label={props.localizations.ArchivedDocumentsSearch} primary={true} value={props.searchArchive} onChange={changeArchiveSearch} />}
				{showStatusDd &&
					<SpheraDropdownEx
						className="includeStatusDropdown"
						type="multi-select"
						value={startOptionValue}
						options={options}
						width="600px"
						onChange={handleincludeChange}
					/>
				}
			</div>
			<div className="dsCriteriaButtonDiv">
				<SpheraButtonEx text={props.localizations.Search} primary onClick={getData} disabled={disableSearch} />
			</div>
			<div>
				<HistoryGrid searchArchive={searchArchive} customer={props.customer} dateFormatId={props.customer.dateFormatId} localizations={props.localizations}
					pending={pending} users={users} dataState={dataState} dataResult={dataResult} dataStateChange={dataStateChange}
					showColumnSelect={showColumnSelect} downloadGrid={downloadGrid} setdownloadGrid={setdownloadGrid} setshowColumnSelect={setshowColumnSelect}/>
			</div>
		</Layout>
	);
}
