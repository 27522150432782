import React from 'react';
import { useNavigate } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import useFetchWithMsal from '../services/useFetchWithMsal';
import { apiRoutes } from '../services/apiRoutes';
import { FilterData } from '../Data/FilterData';
import { SiteData } from '../Data/SiteData';
import { CustomerData } from '../Data/CustomerData';
import { Layout } from '../components/MasterPage/Layout';
import { FiltersGrid } from '../components/Grid/FiltersGrid';
import { AddEditFilterModal } from '../components/Modals/AddEditFilterModal';
import { DeleteFilterModal } from '../components/Modals/DeleteFilterModal';
import { AssignFilterToGroupModal } from '../components/Modals/AssignFilterToGroupModal';
import { AssignFilterToSiteModal } from '../components/Modals/AssignFilterToSiteModal';
import { ContextMenu } from '../components/ContextMenu';

export function Filters(props) {
	let navigate = useNavigate();
	const { execute, result } = useFetchWithMsal({
		scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
	});
	const { getFilterData, postFilter, deleteFilter, assignFilterToSites, assignFilterToGroups, getUserGroups, getFilter, getFilterTitles } = FilterData({ execute: execute, customer: props.customer });
	const { getSites } = SiteData({ execute: execute, customer: props.customer });
	const { getColumns } = CustomerData({ execute: execute, customer: props.customer });

	const [titles, settitles] = React.useState({});
	const [success, setSuccess] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState("");
	const [sitesSelected, setsitesSelected] = React.useState([]);
	const [groupsSelected, setgroupsSelected] = React.useState([]);
	const [sites, setsites] = React.useState([]);
	const [groups, setgroups] = React.useState([]);
	const [localesSelected, setlocalesSelected] = React.useState([]);
	const [MatchFilters, setMatchFilters] = React.useState([]);
	const [Cc1Include, setCc1Include] = React.useState([]);
	const [Cc2Include, setCc2Include] = React.useState([]);
	const [Cc3Include, setCc3Include] = React.useState([]);
	const [MfgInclude, setMfgInclude] = React.useState([]);
	const [TagInclude, setTagInclude] = React.useState([]);
	const [ExactMatchExclude, setExactMatchExclude] = React.useState([]);
	const [Cc1Exclude, setCc1Exclude] = React.useState([]);
	const [Cc2Exclude, setCc2Exclude] = React.useState([]);
	const [Cc3Exclude, setCc3Exclude] = React.useState([]);
	const [MfgExclude, setMfgExclude] = React.useState([]);
	const [TagExclude, setTagExclude] = React.useState([]);
	const [downloadGrid, setdownloadGrid] = React.useState(false);
	const [showColumnSelect, setshowColumnSelect] = React.useState(false);
	const [buttonsConfig, setbuttonsConfig] = React.useState([]);
	const [localesDropdownData, setlocalesDropdownData] = React.useState([]);
	const [currentFilterId, setcurrentFilterId] = React.useState(0);
	const [showDeleteFilterModal, setshowDeleteFilterModal] = React.useState(false);
	const [showAssignToGroupModal, setshowAssignToGroupModal] = React.useState(false);
	const [showAssignToSiteModal, setshowAssignToSiteModal] = React.useState(false);
	const [show, setshow] = React.useState(false);
	const [selectedFilter, setselectedFilter] = React.useState({});
	const [filters, setfilters] = React.useState([]);
	const [originalfilters, setoriginalfilters] = React.useState([]);
	const [dataState, setDataState] = React.useState({ skip: 0, take: 25 });
	const [pending, setPending] = React.useState(false);
	const [showContext, setshowContext] = React.useState(false);
	const [dataResult, setDataResult] = React.useState(process(originalfilters, dataState));
	const dataStateChange = (event) => {
		setDataResult(process(filters, event.dataState));
		setDataState(event.dataState);
	};

	const offSet = React.useRef({ left: 0, top: 0 });
	const filterMenuClick = (filterId, e) => {
		setcurrentFilterId(filterId);
		e.preventDefault();
		offSet.current = {
			left: e.clientX,
			top: e.clientY,
		};
		setshowContext(true);
	};

	const menuItems = [props.localizations.Delete, props.localizations.Edit, props.localizations.AssignToSite, props.localizations.AssignToGroup];
	const rowContextClick = (e) => {
		if (e.item.text === props.localizations.Delete)
			toggleDeleteFilterModal();
		else if (e.item.text === props.localizations.Edit)
			toggleModal(currentFilterId);
		else if (e.item.text === props.localizations.AssignToSite)
			toggleAssignFilterToSite();
		else
			toggleAssignFilterToGroup();
	};

	const getData = () => {
		setPending(true);
		execute("GET", apiRoutes.documents.languages).then(
			(json) => {
				if (json != null) {
					for (var i = 0; i < json.length; i++) {
						json[i].label = (props.localizations[json[i].languageName] ?? json[i].languageName) + " - " + json[i].country;
						json[i].value = json[i].languageId;
					}
					setlocalesDropdownData(json);
				}
			}
		);

		getUserGroups().then((data) => {
			setgroups(data);
			getFilterData(data).then((data) => {
				if (data != null) {
					setPending(false);
					setfilters(data);
					setDataResult(process(data, { skip: 0, take: 25 }));
					setDataState({ skip: 0, take: 25 });
					setoriginalfilters(data);
				}
			});
		});
		
		getSites().then((data) => {
			setsites(data);
		});

		getColumns(props.languageValue.localeId).then((data) => {
			settitles(getFilterTitles(data.columns.materialColumns));
		});
	};

	const save = () => {
		setPending(true);
		postFilter(MatchFilters, selectedFilter, localesSelected, Cc1Include, Cc2Include, Cc3Include,
			MfgInclude, TagInclude, ExactMatchExclude, Cc1Exclude, Cc2Exclude, Cc3Exclude, MfgExclude, TagExclude).then(() => {
			setshow(!show);
			getData();
			setSuccess(true);
			setTimeout(function () {
				setSuccess(false);
			}, 5000);
		})
		.catch((error) => {
			setError(true);
			setErrorMessage(props.localizations.RetrieveSiteError);
		});
	};

	const submitDeleteFilter = async () => {
		setPending(true);
		setshowDeleteFilterModal(!showDeleteFilterModal);
		deleteFilter(currentFilterId).then(async () => {
			setSuccess(true);
			setTimeout(function () {
				setSuccess(false);
			}, 5000);
			await getData();
		})
		.catch((error) => {
			setError(true);
			setErrorMessage(props.localizations.RetrieveSiteError);
		});
	};

	const submitAssignFilterGroupFilter = async () => {
		setPending(true);
		setshowAssignToGroupModal(!showAssignToGroupModal);
		await assignFilterToGroups(groupsSelected, currentFilterId).then(async () => {
			setSuccess(true);
			setTimeout(function () {
				setSuccess(false);
			}, 5000);
			await getData();
		})
		.catch((error) => {
			setError(true);
			setErrorMessage(props.localizations.RetrieveSiteError);
		});
	};

	const submitAssignFilterSiteFilter = async () => {
		setPending(true);
		setshowAssignToSiteModal(!showAssignToSiteModal);
		await assignFilterToSites(sitesSelected, currentFilterId).then(async () => {
			setSuccess(true);
			setTimeout(function () {
				setSuccess(false);
			}, 5000);
			await getData();
		})
		.catch((error) => {
			setError(true);
			setErrorMessage(props.localizations.RetrieveSiteError);
		});
	};

	const handleLocalesDropdownChange = (e) => {
		localesDropdownData.forEach(x => {
			x.show = false;
		});
		e.value.forEach(x => {
			let phrase = localesDropdownData.find(y => x.value === y.value);
			if (phrase)
				phrase.show = true;
		});

		let locales = e.value;
		setlocalesSelected(locales);
		setlocalesDropdownData(localesDropdownData);
	};

	const handleSitesDropdownChange = (e) => {
		sites.forEach(x => {
			x.show = false;
		});
		e.value.forEach(x => {
			let site = sites.find(y => x.value === y.value);
			if (site)
				site.show = true;
		});

		let selecSites = e.value;
		setsitesSelected(selecSites);
		setsites(sites);
	};

	const handleGroupsDropdownChange = (e) => {
		groups.forEach(x => {
			x.show = false;
		});
		e.value.forEach(x => {
			let group = groups.find(y => x.value === y.value);
			if (group)
				group.show = true;
		});

		let selecGroups = e.value;
		setgroupsSelected(selecGroups);
		setgroups(groups);
	};

	const toggleDeleteFilterModal = () => {
		setshowDeleteFilterModal(!showDeleteFilterModal);
	};

	const toggleAssignFilterToSite = () => {
		setshowAssignToSiteModal(!showAssignToSiteModal);
		let filter = originalfilters.filter(x => x.id === currentFilterId)[0];
		let selected = sites.filter(x => filter.publicSiteFilters.filter(y => y.publicSiteId === x.site.id).length > 0);
		setsitesSelected(selected);
	};

	const toggleAssignFilterToGroup = () => {
		setshowAssignToGroupModal(!showAssignToGroupModal);
		let filter = originalfilters.filter(x => x.id === currentFilterId)[0];
		let selected = groups.filter(x => filter.customerGroupFilters.filter(y => y.groupId === x.id).length > 0);
		setgroupsSelected(selected);
	};

	const toggleModal = (filterId) => {
		setshow(!show);
		if (show)
			return;

		if (filterId != null) {
			var { sFilter, lSelected, lCc1Include, lCc2Include, lCc3Include, lMfgInclude, lTagInclude,
				lExactMatchExclude, lCc1Exclude, lCc2Exclude, lCc3Exclude, lMfgExclude, lTagExclude, filter } = getFilter(filterId, originalfilters, localesDropdownData);

			setMatchFilters(sFilter);
			setlocalesSelected(lSelected);
			setCc1Include(lCc1Include);
			setCc2Include(lCc2Include);
			setCc3Include(lCc3Include);
			setMfgInclude(lMfgInclude);
			setTagInclude(lTagInclude);
			setExactMatchExclude(lExactMatchExclude);
			setCc1Exclude(lCc1Exclude);
			setCc2Exclude(lCc2Exclude);
			setCc3Exclude(lCc3Exclude);
			setMfgExclude(lMfgExclude);
			setTagExclude(lTagExclude);
			setselectedFilter(filter);
		}
		else {
			setselectedFilter({});
			setMatchFilters([]);
			setlocalesSelected([]);
			setCc1Include([]);
			setCc2Include([]);
			setCc3Include([]);
			setMfgInclude([]);
			setTagInclude([]);
			setExactMatchExclude([]);
			setCc1Exclude([]);
			setCc2Exclude([]);
			setCc3Exclude([]);
			setMfgExclude([]);
			setTagExclude([]);
		}
	};

	const [gotData, setgotData] = React.useState(false);
	React.useEffect(() => {
		if (gotData || result == null)
			return;
		if (props.currentTenent.CustomerId != null && !props.currentTenent.IsEditFiltersUser)
			navigate("/");
		setgotData(true);
	}, [execute]);

	React.useEffect(() => {
		if (gotData && execute != null)
			getData();
	}, [gotData, execute]);

	React.useEffect(() => {
		if (props.localizations.IncludeActive != null)
			setbuttonsConfig([
				{
					type: 'primary',
					text: props.localizations.AddFilter,
					onClick: () => { toggleModal(); }
				},
				{
					type: 'secondary',
					icon: 'icon-ui-general-libraries',
					tooltip: props.localizations.Columns,
					position: "top",
					onClick: () => { setshowColumnSelect(true); }
				},
				{
					type: 'secondary',
					icon: 'icon-ui-general-download',
					tooltip: props.localizations.Download,
					position: "top",
					onClick: () => { setdownloadGrid(true); }
				}
			]);
	}, [props.localizations]);

	return (
		<Layout versionNumber={props.versionNumber} success={success} setSuccess={setSuccess} error={error} setError={setError} errorMessage={errorMessage} buttonsConfig={buttonsConfig} currentTenent={props.currentTenent} localizations={props.localizations} changeLanguage={props.changeLanguage} languageValue={props.languageValue} >
			<div>
				<FiltersGrid filterMenuClick={filterMenuClick} customer={props.customer} dateFormatId={props.customer.dateFormatId} localizations={props.localizations}
					pending={pending} filters={filters} dataState={dataState} dataResult={dataResult} dataStateChange={dataStateChange}
					showColumnSelect={showColumnSelect} downloadGrid={downloadGrid} setdownloadGrid={setdownloadGrid} setshowColumnSelect={setshowColumnSelect} />

				<ContextMenu menuItems={menuItems} offSet={offSet} rowContextClick={rowContextClick} showContext={showContext} setshowContext={setshowContext} />

				<AddEditFilterModal localizations={props.localizations} titles={titles} selectedFilter={selectedFilter} setselectedFilter={setselectedFilter}
					show={show} toggleModal={toggleModal} save={save} handleLocalesDropdownChange={handleLocalesDropdownChange} localesSelected={localesSelected}
					localesDropdownData={localesDropdownData} MatchFilters={MatchFilters} setMatchFilters={setMatchFilters}
					Cc1Include={Cc1Include} setCc1Include={setCc1Include} Cc2Include={Cc2Include} setCc2Include={setCc2Include}
					Cc3Include={Cc3Include} setCc3Include={setCc3Include} MfgInclude={MfgInclude} setMfgInclude={setMfgInclude}
					TagInclude={TagInclude} setTagInclude={setTagInclude} setExactMatchExclude={setExactMatchExclude} ExactMatchExclude={ExactMatchExclude}
					Cc1Exclude={Cc1Exclude} setCc1Exclude={setCc1Exclude} Cc2Exclude={Cc2Exclude} setCc2Exclude={setCc2Exclude}
					Cc3Exclude={Cc3Exclude} setCc3Exclude={setCc3Exclude} MfgExclude={MfgExclude} setMfgExclude={setMfgExclude}
					TagExclude={TagExclude} setTagExclude={setTagExclude} />
				<DeleteFilterModal localizations={props.localizations} showDeleteFilterModal={showDeleteFilterModal} toggleDeleteFilterModal={toggleDeleteFilterModal} submitDeleteFilter={submitDeleteFilter} />
				<AssignFilterToGroupModal localizations={props.localizations} handleGroupsDropdownChange={handleGroupsDropdownChange} groups={groups} groupsSelected={groupsSelected} showAssignToGroupModal={showAssignToGroupModal} toggleAssignFilterToGroup={toggleAssignFilterToGroup} submitAssignFilterGroupFilter={submitAssignFilterGroupFilter} />
				<AssignFilterToSiteModal localizations={props.localizations} handleSitesDropdownChange={handleSitesDropdownChange} sites={sites} sitesSelected={sitesSelected} showAssignToSiteModal={showAssignToSiteModal} toggleAssignFilterToSite={toggleAssignFilterToSite} submitAssignFilterSiteFilter={submitAssignFilterSiteFilter} />
			</div>
		</Layout>
	);
}
