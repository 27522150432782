import React from 'react';
import { SpheraModal } from '@sphera-cloud/component-ui-reactjs';
import { SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";

export function DeleteFilterModal(props) {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">{props.localizations.Loading}</span>
            <SpheraSpinner version={1} color="dark-1" />
        </div>
    );

    return (
        <div>
            {props.showDeleteFilterModal && (
                <SpheraModal className="DeleteFilterModal" title={props.localizations.Delete} width={400} height={300}
                    onClose={props.toggleDeleteFilterModal}
                    onCancel={props.toggleDeleteFilterModal}
                    cancelText={props.localizations.Cancel}
                    actionText={props.localizations.Delete}
                    onAction={props.submitDeleteFilter}
                >
                    <div className="DeleteFilterModalDiv" >
                        <div>{props.localizations.DeleteFilterConfirm}</div>
                        {props.modalPending && loadingPanel}
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}