import React from 'react';
import { SpheraModal, SpheraDropdownEx } from '@sphera-cloud/component-ui-reactjs';
import { SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";

export function AssignFilterToSiteModal(props) {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">{props.localizations.Loading}</span>
            <SpheraSpinner version={1} color="dark-1" />
        </div>
    );

    return (
        <div>
            {props.showAssignToSiteModal && (
                <SpheraModal className="AssignFilterToSite" title={props.localizations.AssignToSite} width={400} height={300}
                    onClose={props.toggleAssignFilterToSite}
                    onCancel={props.toggleAssignFilterToSite}
                    cancelText={props.localizations.Cancel}
                    actionText={props.localizations.Save}
                    onAction={props.submitAssignFilterSiteFilter}
                >
                    <div className="AssignFilterToSiteDiv" >
                        <br />
                        <SpheraDropdownEx wrap filterable label={props.localizations.CustomerPortal}
                            type="multi-select" onChange={props.handleSitesDropdownChange} options={props.sites}
                            width="100%" value={props.sitesSelected} />
                        {props.modalPending && loadingPanel}
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}