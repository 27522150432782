import React, { useState } from 'react';
import { Grid, GridColumn as Column, GridColumnMenuWrapper, GridColumnMenuFilter, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { SpheraTooltip, SpheraSwitchToggle } from "@sphera-cloud/component-ui-reactjs";
import dateFormats from "../../Data/dateFormats.json";
import customersGridColumns from "../../Data/customerGridColumns.json";
import { LoadingPanel } from '../LoadingPanel';
import { TextCell } from './Cell/TextCell';
import { CustomColumnMenu } from "../CustomColumnMenu";
import { ColumnSelectButton } from "../ColumnSelectButton";
import { ColumnManager } from "../../services/columnManager";

export function CustomersGrid(props) {
    const [exportobj, setexportobj] = useState({});

    const { columnResize, columnReorder, onColumnsSubmit, defaultSettings } = ColumnManager(customersGridColumns, "customerGridSettings");
    const [stateColumns, setStateColumns] = React.useState(defaultSettings);

    let dateFormat = "{0:dd/MM/yyyy}";
    let dateFormatFilter = "dd/MM/yyyy";
    if (props.dateFormatId != null) {
        let format = dateFormats.find(x => x.value === props.dateFormatId);
        if (format != null) {
            dateFormat = "{0:" + format.text + "}";
            dateFormatFilter = format.text;
        }
    }
    const exportFunction = () => { exportobj.save(); };
    const handleColumnSubmit = (columns) => {
        let newcolumns = onColumnsSubmit(columns);
        setStateColumns(newcolumns);
    }

    async function copyTextToClipboard(text, e) {
        let icon = e.target.parentNode.getElementsByClassName("icon-ui-general-copy")[0];
        icon.className = "icon-ui-general-check"
        setTimeout(() => {
            icon.className = "icon-ui-general-copy";
        }, 3000);

        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    }

    React.useEffect(() => {
        if (props.downloadGrid) {
            exportFunction();
            props.setdownloadGrid(false);
        }
    }, [props.downloadGrid]);

    return (
        <div style={{ height: 'calc(100vh - 260px)', minHeight: '300px' }}>
            <ColumnSelectButton open={props.showColumnSelect} setopen={props.setshowColumnSelect} onColumnsSubmit={handleColumnSubmit} originalColumns={customersGridColumns} columns={stateColumns} localizations={props.localizations} />
            <ExcelExport data={props.customers} ref={exporter => setexportobj(exporter)}>
                {stateColumns.map(
                    (column, idx) =>
                        column.show && (
                            <ExcelExportColumn
                                key={idx}
                                field={column.field}
                                title={props.localizations[column.title]}
                            />
                        )
                )}
            </ExcelExport>
            <Grid
                className="sphera-grid"
                style={{
                    height: '100%',
                    width: '100%'
                }}
                data={props.dataResult}
                {...props.dataState}
                onDataStateChange={props.dataStateChange}
                sortable={true}
                reorderable={true}
                resizable={true}
                scrollable="scrollable"
                onColumnReorder={columnReorder}
                onColumnResize={columnResize}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [25, 50, 100]
                }}
            >
                <GridToolbar>
                    <div style={{ marginRight: '10px' }}>
                        <label className="k-label switch-label" style={{ marginRight: '4px', marginTop: '5px', cursor: 'pointer', color: '#0877A7' }}>{props.localizations.ShowAll}
                            <span style={{ marginLeft: '4px' }}>
                                <SpheraSwitchToggle checked={props.showAll} onChange={props.handleShowAllUsersChange} />
                            </span>
                        </label>
                    </div>
                </GridToolbar>
                {stateColumns.map(
                    (column, idx) =>
                        column.show && (
                            <Column
                                key={idx}
                                cell={(props2) => <TextCell field={column.field} searchedPhrase="" isLink={column.isLink} isCopy={column.isCopy} isMenu={column.isMenu} linkClick={column.field == "action" ? props.editCustomerClick : copyTextToClipboard} clickId={column.field == "action" ? props2.dataItem.customerId : props2.dataItem.spheraCustomerId} DisplayValue={props2.dataItem[column.field]} />}
                                field={column.field}
                                title={props.localizations[column.title]}
                                filter={column.filter}
                                width={column.noFilterOrSort ? 50 : column.width}
                                minResizableWidth={100}
                                format={dateFormat}
                                headerClassName={GridColumnMenuFilter.active(column.field, props.dataState.filter) ? 'active' : ''}
                                headerCell={(props2) => {
                                    if (column.isMenu)
                                        return ("");
                                    else
                                        return (<span className="k-cell-inner">
                                            <span className="k-link" onClick={props2.onClick} >
                                                <SpheraTooltip anchor="target" position="top" title={props.localizations[column.title]} >
                                                    <span className="k-column-title">{props.localizations[column.title]}</span>
                                                </SpheraTooltip>
                                                {props2.children}
                                            </span>
                                            <GridColumnMenuWrapper
                                                {...props2.columnMenuWrapperProps}
                                            ></GridColumnMenuWrapper>
                                        </span>);
                                }}
                                columnMenu={(props2) => (
                                    <CustomColumnMenu {...props2} data={props.customers} checkboxFilter={column.checkboxFilter} columns={customersGridColumns} dateFormatFilter={dateFormatFilter} />
                                )}
                            />
                        )
                )}
            </Grid>
            {props.pending && <LoadingPanel localizations={props.localizations} />}
        </div>
    );
}