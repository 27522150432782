import * as React from 'react';
import { GridColumnMenuFilter, GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';
import { DateFilterCell } from './Grid/Cell/DateFilterCell';

export const CustomColumnMenu = props => {
    const dateFormatFilter = props.dateFormatFilter;

    if (props.checkboxFilter)
        return <div>
            <GridColumnMenuCheckboxFilter
                {...props}
                data={props.data}
                expanded={true}
                searchBox={() => null}
            />
        </div>;
    else
        return <div>
            <GridColumnMenuFilter
                {...props}
                onExpandChange={() => { } }
                expanded={true}
                filterUI={props.useDateFilter ? (props) => <DateFilterCell {...props} dateFormatFilter={dateFormatFilter} /> : false}
            />
        </div>;
};