import React, { useContext }from "react";
import WidgetContext from "./WidgetContext";
import { SpheraButtonEx } from "@sphera-cloud/component-ui-reactjs";
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardSubtitle } from "@progress/kendo-react-layout";
import { useNavigate} from "react-router-dom";

export const WidgetCard = ({card, toggleConfirmDeleteDialog, localizations}) => {

  const navigate = useNavigate();
  const widgetCtx = useContext(WidgetContext);

  return (
    <Card
      style={{
        width: 340,
        boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
        marginTop: "15px",
      }}
    >
      <CardHeader
        className="k-hbox"
        style={{
          background: "transparent",
        }}
      >
        <div>
          <CardTitle
            style={{
              marginBottom: "4px",
            }}
          >
            {card.name}
          </CardTitle>
          <CardSubtitle>
            <span style={{ wordBreak: "break-word" }}>
              {card.description}
            </span>
          </CardSubtitle>
        </div>
      </CardHeader>
      <CardBody className="border-bottom">
         {!card.isEdit && (
          <p>
            <span>
              <b>Domain: </b>
            </span>
            <span style={{ wordBreak: "break-word" }}>
              {card.domain}
            </span>
            <br />
          </p>
        )}
      </CardBody>
      <CardActions className="mt-2 k-form-buttons">
        {!card.isEdit && (
          <div className="row col-12 p-0">
            <div className="col-6 float-left px-3">
              <SpheraButtonEx
                text="Delete"
                secondary={true}
                onClick={(event) => {
                  widgetCtx.setCurrent(card);
                  toggleConfirmDeleteDialog(event);
                }}
              />
            </div>
            <div
              className="col-6 float-right px-0 mx-0"
              style={{ paddingRight: "-10px!important" }}
            >
              <SpheraButtonEx
                text="Edit"
                primary={true}
                onClick={(event) => {
                  navigate(`/widgets/${card.id}`);
                }}
                style={{ float: "right" }}
              />
            </div>
          </div>
        )}
        {card.isEdit && (
          <div className="row col-12 mb-2  mx-auto">
            <div className="col-6">
              <SpheraButtonEx
                text={localizations.Cancel}
                secondary="true"
                onClick={(event) => {
                //   formResetRef?.current.click();
                //   toggleEdit(selectedCard);
                //   setError(false);
                //   setIsDuplicate(false);
                }}
              />
            </div>
            <div
              className="col-6"
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <SpheraButtonEx
                text={localizations.Update}
                primary="true"
                onClick={async (event) => {
                  event.preventDefault();

                //   const isdupe = await verifyDuplicateExists(
                //     selectedCard.site.url
                //   );

                //   if (!isdupe) {
                //     formSubmitRef?.current.click();
                //   } else {
                //     setIsDuplicate(false);
                //     return;
                //   }
                }}
                type="submit"
              />
            </div>
          </div>
        )}
      </CardActions>
    </Card>
  );
};
 export default WidgetCard;