import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ColorGradient } from "@progress/kendo-react-inputs";
import { ExternalDropZone, Upload } from "@progress/kendo-react-upload";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { Sortable } from '@progress/kendo-react-sortable';
import {
    SpheraSpinner, SpheraNotificationGroup, SpheraNotification, SpheraInput, SpheraTextAreaFields, SpheraDropdownEx, SpheraButtonEx, SpheraTooltip
} from "@sphera-cloud/component-ui-reactjs";
import { SortableMaterialColumn } from '../SortableMaterialColumn';
import { CustomColumnWidthsModal } from './CustomColumnWidthsModal';
import { SortableCustomerProperty } from '../SortableCustomerProperty';

export const AddEditPublicSiteModal = (props) => {
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
    } = EditorTools;
    const uploadRef = React.createRef();
    const hint = <span>Drag and drop here </span>;
    const note = <span></span>;

    const toggleCustomColumn = () => { };
    const [showWidthModal, setshowWidthModal] = React.useState(false);
    const [showDetails, setshowDetails] = React.useState(false);
    const [showMaterial, setshowMaterial] = React.useState(false);

    const toggleWidthsModal = (isMaterial) => {
        setshowWidthModal(!showWidthModal);

        if (showWidthModal) {
            setshowDetails(false);
            setshowMaterial(false);
        }
        else {
            if (!isMaterial) {
                setshowDetails(true);
                setshowMaterial(false);
            }
            else {
                setshowMaterial(true);
                setshowDetails(false);
            }
        }
    }

    const onDragOver = event => {
        props.setmaterialColumns(event.newState);
    };
    const onNavigate = event => {
        props.setmaterialColumns(event.newState);
    };

    const docOnDragOver = event => {
        props.setdocumentColumns(event.newState);
    };
    const docOnNavigate = event => {
        props.setdocumentColumns(event.newState);
    };

    const changeToken = event => {
        let site = props.selectedSite;
        site.apiToken = event.value;
        props.setselectedSite(site);
    }
    const changeName = event => {
        let site = props.selectedSite;
        site.name = event.value;
        props.setselectedSite(site);
    }
    const changeDescription = event => {
        let site = props.selectedSite;
        site.description = event.value;
        props.setselectedSite(site);
    }
    const changePath = event => {
        let site = props.selectedSite;
        site.url = event.value;
        props.setselectedSite(site);
    }

    const pathFieldValidator = (value) => {
        if (!value || value?.length === 0)
            return props.localizations.FieldRequired;
        let pattern = /\s/g;
        let result = value.match(pattern);

        if (result && result.length > 0)
            return props.localizations.NoSpacesError;
    };

    return (
        <>
            <Modal
                isOpen={props.showModal}
                toggle={props.toggle}
                size="lg" style={{ maxWidth: '1000px', width: '100%' }}
            >
                <ModalHeader toggle={props.toggle}>
                    {props.localizations.AddEditSite}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="row mb-3">
                            <div className="col-3">
                                <SpheraTooltip position="top">
                                    <SpheraInput
                                        title={props.localizations.SiteName}
                                        label={props.localizations.SiteName}
                                        name="name"
                                        required
                                        type="text"
                                        placeholder={props.localizations.SiteName}
                                        maxLength={30}
                                        value={props.selectedSite.name}
                                        onChange={changeName}
                                    />
                                </SpheraTooltip>
                            </div>
                            <div className="col-3">
                                <SpheraTooltip position="top">
                                    <SpheraInput
                                        title={props.localizations.PublishedUrl + `: ${props.baseDomain}/{your path}`}
                                        label={props.localizations.Path}
                                        name="url"
                                        required
                                        type="text"
                                        placeholder={props.localizations.Path}
                                        value={props.selectedSite.url}
                                        onChange={changePath}
                                        validator={pathFieldValidator}
                                    />
                                </SpheraTooltip>
                                {props.isDuplicate ? <div className="searchNotValidText">{props.localizations.SiteNameValidation}</div> : ""}
                            </div>
                            <div className="col-3">
                                <SpheraTooltip position="top">
                                    <SpheraDropdownEx
                                        title={props.localizations.Token}
                                        name="token"
                                        required
                                        label={props.localizations.Token}
                                        options={props.apiTokens}
                                        type="combo-box"
                                        width="18rem"
                                        filterable
                                        value={props.apiTokens.find(x => props.selectedSite.apiToken != null && x.value == props.selectedSite.apiToken.id)}
                                        onChange={changeToken}
                                    />
                                </SpheraTooltip>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <SpheraTooltip position="top">
                                    <SpheraTextAreaFields
                                        title={props.localizations.Description}
                                        label={props.localizations.Description}
                                        name="name"
                                        type="text"
                                        placeholder={props.localizations.Description}
                                        maxLength={255}
                                        value={props.selectedSite.description}
                                        onChange={changeDescription}
                                    />
                                </SpheraTooltip>
                            </div>
                            <div className="col-6">
                                <SpheraDropdownEx wrap filterable label={props.localizations.Filters} onChange={props.handleFilterDropdownChange} options={props.filtersDropdownData} width="100%" value={props.filterSelected} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label className="sphera-label-text" style={{ marginTop: "10px" }}>{props.localizations.DefaultText}</label>
                                <Editor
                                    tools={[
                                        [Bold, Italic, Underline, Strikethrough],
                                        [Subscript, Superscript],
                                        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                        [Indent, Outdent],
                                        [OrderedList, UnorderedList],
                                        FontSize,
                                        FontName,
                                        FormatBlock,
                                        [Undo, Redo],
                                        [Link, Unlink]
                                    ]}
                                    contentStyle={{
                                        height: 200,
                                    }}
                                    value={props.DefaultTextValue} onChange={props.handleDefaultTextValueChange}
                                />
                            </div>
                        </div>

                        <br />
                        <div className="row">
                            <div className="col-4">
                                <label className="sphera-label-text">{props.localizations.HeaderColor}</label>
                            </div>
                            <div className="col-8">
                                <label className="sphera-label-text">Icon</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <ColorGradient
                                    value={props.colorValue}
                                    onChange={props.handlecolorValueChange}
                                    style={{
                                        marginTop: "10px",
                                        width: "242px"
                                    }}
                                />
                            </div>
                            <div className="col-1">
                                <div style={{ background: props.colorValue, width: "60px", height: "60px", marginTop: "27px" }} > </div>
                            </div>
                            <div className="col-4">
                                <div className="sphera-button uploadButtonDiv mx-auto">
                                    <label
                                        htmlFor="logoUpload"
                                        className="mx-auto k-button k-button-md k-button-solid k-button-solid-base k-rounded-md btn-primary uploadLabelButton"
                                    >
                                        {props.localizations.UploadIcon}
                                    </label>
                                    <input
                                        type="file"
                                        name="file"
                                        onChange={props.changeHandler}
                                        id="logoUpload"
                                        className="logoUpload"
                                        style={{ display: "none" }}
                                    />
                                </div>
                                <ExternalDropZone
                                    uploadRef={uploadRef}
                                    customHint={hint}
                                    customNote={note}
                                    style={{
                                        backgroundColor: "#ffffff",
                                        border: "3px dashed #dedede",
                                        width: "200px",
                                    }}
                                />
                                <div />
                            </div>
                            <div className="col-4">
                                {props.loadingLogo && (
                                    <SpheraSpinner version={1} color="dark-1" />
                                )}
                                {(props.logoUrl || props.uploadedFile) && (
                                    <>
                                        <SpheraButtonEx
                                            text={props.localizations.Remove}
                                            primary={true}
                                            icon="icon-ui-general-delete"
                                            onClick={(event) => {
                                                props.removeLogo(event);
                                            }}
                                        />
                                        <img
                                            src={props.uploadedFile ?? props.logoUrl}
                                            alt={"Logo preview"}
                                            style={{
                                                width: 200,
                                                margin: 10,
                                            }}
                                        />
                                    </>
                                )}
                                <Upload
                                    ref={uploadRef}
                                    batch={false}
                                    multiple={false}
                                    defaultFiles={[]}
                                    withCredentials={false}
                                    onAdd={props.changeHandler}
                                    className="hidden"
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row mb-3">
                            <label className="sphera-label-text" style={{ marginTop: "10px" }} >{props.localizations.MaterialColumns}</label>
                            <div className="row">
                                <div className="col-5">
                                    <span className="ColumnsNameHeaderPs"><b>{props.localizations.Column}</b></span>
                                </div>
                                <div className="col-4">
                                    <span className="ColumnsPrivateHeader"><b>{props.localizations.Include}</b></span>
                                </div>
                                <div className="col-3">
                                    <SpheraButtonEx
                                        text={props.localizations.SetColumnWidths}
                                        className="SetWidthsBtn btn"
                                        onClick={() => toggleWidthsModal(true)}
                                    />
                                </div>
                            </div>
                            <div className="sortable">
                                <Sortable idField={'customerColumnId'} data={props.materialColumns} itemUI=
                                    {(props2) => (
                                        <SortableMaterialColumn {...props2} localizations={props.localizations} includecustomcolumn1Change={props.includecustomcolumn1Change}
                                            toggleCustomColumn={toggleCustomColumn} isSettings={false} />
                                    )}
                                    onDragOver={onDragOver} onNavigate={onNavigate} />
                            </div>
                            <label className="sphera-label-text" style={{ marginTop: "10px" }} >{props.localizations.DocumentColumns}</label>
                            <div className="row">
                                <div className="col-5">
                                    <span className="ColumnsNameHeaderPs"><b>{props.localizations.Column}</b></span>
                                </div>
                                <div className="col-4">
                                    <span className="ColumnsPrivateHeader"><b>{props.localizations.Include}</b></span>
                                </div>
                                <div className="col-3">
                                    <SpheraButtonEx
                                        text={props.localizations.SetColumnWidths}
                                        className="SetWidthsBtn btn"
                                        onClick={() => toggleWidthsModal(false)}
                                    />
                                </div>
                            </div>
                            <div className="sortable">
                                <Sortable idField={'customerColumnId'} data={props.documentColumns} itemUI=
                                    {(props2) => (
                                        <SortableMaterialColumn {...props2} localizations={props.localizations} includecustomcolumn1Change={props.includecustomcolumn1Change}
                                            toggleCustomColumn={toggleCustomColumn} isSettings={false} />
                                    )}
                                        onDragOver={docOnDragOver} onNavigate={docOnNavigate} />
                            </div>
                            <label className="sphera-label-text" style={{marginTop: "10px"}} >{props.localizations.PropertyDisplaySettings}</label>
                            <div className="row">
                                <div className="col-5" style={{ fontSize: "12px" }} >
                                    <span><b>{props.localizations.Section}</b></span>
                                </div>
                                <div className="col-2" style={{ fontSize: "12px" }}>
                                    <span><b>{props.localizations.Include}</b></span>
                                </div>
                            </div>
                            <div className="sortable">
                                <Sortable idField={'phraseSectionId'} data={props.PhraseSectionCustomer}
                                    itemUI={(props2) => (
                                        <SortableCustomerProperty {...props2} localizations={props.localizations} propscheckChange={props.propscheckChange} />
                                    )}
                                    onDragOver={props.propOnDragOver} onNavigate={props.propOnNavigate} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <SpheraButtonEx className='cancelBtn' text={props.localizations.Cancel} onClick={props.modalCancelClick} />
                    <SpheraButtonEx primary text={props.localizations.Save} onClick={props.modalSaveClick} />
                </ModalFooter>
                <SpheraNotificationGroup
                    position={{
                        left: '45%',
                        bottom: '2.5rem',
                        alignItems: "flex-center",
                    }}>
                    {props.error && <SpheraNotification closable={true}
                        type="error"
                        theme="dark"
                        text={props.localizations.SaveError + ": " + props.errorMessage}
                        onClose={() => props.setError(false)} />}
                </SpheraNotificationGroup>
            </Modal>
            <CustomColumnWidthsModal localizations={props.localizations} toggleWidthsModal={toggleWidthsModal} showWidthModal={showWidthModal}
                materialColumns={props.materialColumns} documentColumns={props.documentColumns} showMaterial={showMaterial} showDetails={showDetails}
                setmaterialColumns={props.setmaterialColumns} setdocumentColumns={props.setdocumentColumns} />
        </>
    );
};
