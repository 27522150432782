import React from "react";

export const PhraseSection = (props) => {
    const phraseSectionInfo = 1;
    const phraseSectionIn = 3;
    const phraseSectionPh = 9;
    const phraseSectionTrans = 14;

    if (props.section == null || (!props.section.show && props.section.parentPhraseSectionId == null)) 
        return "";

    let hasPhrases = props.section.phrases != null && props.section.phrases.length > 0;
    return (
        <div style={{ paddingLeft: "20px" }}>
            {props.section.phraseSectionType.parentPhraseSectionTypeId == null && (<><hr style={{ borderTop: "1px solid #bbb" }} /></>)}
            {props.section.phraseSectionType.parentPhraseSectionTypeId == null ? (<h5 className={"phraseSectionHeader"} ><strong>{props.section.phraseSectionType.name}</strong></h5>) : (<h5 className={hasPhrases ? "phraseValueHeader" : props.section.parentPhraseSectionId ? "phraseSubHeader" : ""} >{props.section.phraseSectionType.name}</h5>)}

            {hasPhrases ? <div style={{ display: "inline-block", width: "50%", verticalAlign: "text-top", whiteSpace: "pre-line" }}>
                {props.section.phrases.map((x, idx) => {
                    let pics = props.pictograms.filter(y => y.pictogramId == x.pictogramId);
                    if (x.pictogramId == null || pics.length === 0)
                        return <span key={idx + "phrase" + x.phraseSectionId} >{x.phrase}<br /></span>;


                    return <img src={"pictograms/" + pics[0].pictogramSourceId + ".png"} height="100" alt={x.phrase} />;
                })}
            </div> : ""}

            {(props.section.phraseSectionTypeId === phraseSectionPh) &&
                props.properties.sort((a, b) => a.SortOrder - b.SortOrder).map((item, idx) =>
                    <span key={idx + "proptypes"}>
                        {item.propsHtml}
                    </span>
                )
            }
            {(props.section.phraseSectionTypeId === phraseSectionInfo) &&
                props.DocumentInfo
            }
            {(props.section.phraseSectionTypeId === phraseSectionIn) &&
                props.Ingredients
            }
            {(props.section.phraseSectionTypeId === phraseSectionTrans) &&
                props.Transportation
            }
            {props.section.children.map((x, index) => {
                return <PhraseSection section={x} key={x.phraseSectionType.name} pictograms={props.pictograms} languageValue={props.languageValue} />
            })}
        </div>
    );
};