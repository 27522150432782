import { useMsal } from '@azure/msal-react';
import { cookieHandler } from "../services/cookieHandler";

export function Logout() {
    const { eraseCookie } = cookieHandler();
    eraseCookie(".AspNet.Cookies");
    eraseCookie("ASP.NET_SessionId");
    const { instance } = useMsal();
    localStorage.removeItem("sphdsSearchPhrase");
    instance.logoutRedirect();
	return ("");
}
