import React from 'react';
import { Input, Col, Row } from 'reactstrap';
import useFetchWithMsal from '../services/useFetchWithMsal';
import { SpheraRadioButtonGroup, SpheraDropdownEx, SpheraCheckbox } from "@sphera-cloud/component-ui-reactjs";
import { cookieHandler } from "../services/cookieHandler";


export function DocumentSeachBox(props) {
    const { setCookie } = cookieHandler();

    const rbData = [
        { label: props.localizations.MatchWords, value: '1' },
        { label: props.localizations.MatchPhrase, value: '2' }
    ]

    const { execute } = useFetchWithMsal({
        scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
    });

    const searchPhraseChange = (e) => {
        props.setsearchPhrase(e.target.value);
        props.setsearchPhraseNoWildcard(e.target.value.replaceAll("?", "").replaceAll("*", "").trim());
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.setsearchPhrase(e.target.value);
            props.SubmitSearch(execute);
        }
    }

    const handleRbChange = (e) => {
        props.setsearchType(e.value);
        setCookie("SpheraSearchType", e.value)
    }

    const changeArchiveSearch = () => {
        props.setsearchArchive(!props.searchArchive);
    }

    return (
        <div>
            <div className="searchFieldDiv">
                <Row>
                    <Col lg="8">
                        <Input autoComplete="off" className={props.searchPhraseNoWildcard.length < 3 && props.searchPhrase.length !== 0 ? "searchNotValid" : ""} type="text" name="search" id="searchtext" placeholder={props.localizations.SearchPlaceholder} onChange={searchPhraseChange} onKeyPress={handleKeyPress} value={props.searchPhrase} />
                        <button data-testid="DocumentSearchBtn" title={props.localizations.Search} type="button" className="btn btn-primary DocumentSearchBtn" onClick={() => props.SubmitSearch(execute)} ><i className="icon-ui-general-search" style={{ fontSize: '25px', backgroundColor: "white" }} /></button>
                        <div className={props.searchPhraseNoWildcard.length < 3 && props.searchPhrase.length !== 0 ? "searchNotValidText" : "searchNotValidTextHidden"} >{props.localizations.SearchNotValidText}</div>
                    </Col>
                    <Col id="phraseRbg" lg="4">
                        <SpheraRadioButtonGroup data={rbData} direction="vertical" value={props.searchType} onChange={handleRbChange} />
                    </Col> 
                </Row>

                {props.preSearch &&
                    <>
                        <SpheraDropdownEx wrap filterable label={props.localizations.LanguageFilter} type="multi-select" onChange={props.handleLocalesDropdownChange} options={props.localesDropdownData} width="100%" value={props.localesSelected} />
                    {props.currentTenent.IsViewArchivedDocumentsUser && <div style={{ marginTop: "15px" }}><SpheraCheckbox label={props.localizations.ArchiveMaterialSearch} primary={true} value={props.searchArchive} onChange={changeArchiveSearch} /></div>}
                    </>
                }
            </div>
        </div>
    );
}