import React from 'react';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SpheraModal, SpheraSubTab, SpheraTab, SpheraDropdownEx } from '@sphera-cloud/component-ui-reactjs';
import HIMS from '../../images/HIMS.png';
import NFPA from '../../images/NFPA.png';
import { LoadingPanel } from '../LoadingPanel';
import { DateFormater } from "../../services/DateFormater";
import { PhraseSection } from "../PhraseSection";

export function DocumentModal(props) {
    const [maximize, setmaximize] = React.useState(true);
    const [minimize, setminimize] = React.useState(false);
    const [height, setHeight] = React.useState("90%");
    const [width, setWidth] = React.useState(1000);

    const { formatDate } = DateFormater();


    const [showDd, setshowDd] = React.useState(false);
    const showHideClick = () => {
        setshowDd(!showDd);
    }

    let Ingredients = null;
    if (props.standardIngredients.length > 0 || props.fullIngredients.length > 0)
        Ingredients = <>
        <h4 style={{ marginLeft: "20px" }} className="phraseSubHeader" >{props.localizations.Ingredients}</h4>
        {
            props.standardIngredients.length > 0 &&
            (<><h4 style={{ marginLeft: "40px" }} className="phraseValueHeader">{props.localizations.Standard}</h4>
                <Grid
                    className="sphera-grid"
                    style={{
                        height: '330px',
                        width: '95%',
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "10px",
                        maxHeight: '380px'
                    }}
                    data={props.standardDataResult}
                    {...props.standardDataState}
                    onDataStateChange={props.standardDataStateChange}
                    sortable={true}
                    reorderable={true}
                    resizable={true}
                    scrollable="scrollable"
                    pageable={{
                        buttonCount: 4,
                        pageSizes: [25, 50, 100]
                    }}
                >
                    <Column
                        field="name"
                        minResizableWidth={100}
                        title={props.localizations.Name}
                        filter="text"
                    />
                    <Column
                        field="cas"
                        minResizableWidth={100}
                        title={props.localizations.CasNumber}
                        filter="text"
                    />
                    <Column
                        field="ec"
                        minResizableWidth={100}
                        title={props.localizations.EcNumber}
                        filter="text"
                    />
                    <Column
                        field="concentrationValue"
                        minResizableWidth={100}
                        title={props.localizations.ConcentrationValue}
                        filter="text"
                    />
                </Grid></>
            )
        }
        < br />
        {
            props.fullIngredients.length > 0 &&
            (<><h4 style={{marginLeft: "40px"}} className="phraseValueHeader">{props.localizations.FullFormula}</h4>
                <Grid
                    className="sphera-grid"
                    style={{
                        height: '330px',
                        width: '95%',
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "10px",
                        maxHeight: '380px'
                    }}
                    data={props.fullDataResult}
                    {...props.fullDataState}
                    onDataStateChange={props.fullDataStateChange}
                    sortable={true}
                    reorderable={true}
                    resizable={true}
                    scrollable="scrollable"
                    pageable={{
                        buttonCount: 4,
                        pageSizes: [25, 50, 100]
                    }}
                >
                    <Column
                        field="name"
                        minResizableWidth={100}
                        title={props.localizations.Name}
                        filter="text"
                    />
                    <Column
                        field="cas"
                        minResizableWidth={100}
                        title={props.localizations.CasNumber}
                        filter="text"
                    />
                    <Column
                        field="ec"
                        minResizableWidth={100}
                        title={props.localizations.EcNumber}
                        filter="text"
                    />
                    <Column
                        field="concentrationValue"
                        minResizableWidth={100}
                        title={props.localizations.ConcentrationValue}
                        filter="text"
                    />
                </Grid>
            </>
            )
        }
        </>;

    let Transportation = null;
    if (props.Transportation.length > 0)
        Transportation = <>
            <h4 style={{ marginLeft: "40px" }} className="phraseValueHeader">{props.localizations.Transportation}</h4>
            <Grid
                className="sphera-grid"
                style={{
                    height: '330px',
                    width: '95%',
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "10px",
                    maxHeight: '380px'
                }}
                data={props.transportationDataResult}
                {...props.transportationDataState}
                onDataStateChange={props.transportationStateChange}
                sortable={true}
                reorderable={true}
                resizable={true}
                scrollable="scrollable"
                pageable={{
                    buttonCount: 4,
                    pageSizes: [25, 50, 100]
                }}
            >
                <Column
                    field="name"
                    minResizableWidth={100}
                    title={props.localizations.ShippingName}
                    filter="text"
                />
                <Column
                    field="unNumber"
                    minResizableWidth={100}
                    title={props.localizations.UNNumber}
                    filter="text"
                />

                <Column
                    field="classes"
                    minResizableWidth={100}
                    title={props.localizations.Classes}
                    filter="text"
                />
                <Column
                    field="packing"
                    minResizableWidth={100}
                    title={props.localizations.Packing}
                    filter="text"
                />
                <Column
                    field="agency"
                    minResizableWidth={100}
                    title={props.localizations.Agency}
                    filter="unNumber"
                />
                <Column
                    field="placards"
                    minResizableWidth={100}
                    title={props.localizations.Placards}
                    filter="text"
                    cell={(props2) =>
                        <td className="k-table-td" style={{ textAlign: "center", whiteSpace: "normal" }}>
                            {props2.dataItem.hazards.map((x, index) => x.pictogramSourceId ? <><img src={"pictograms/" + x.pictogramSourceId + ".png"} alt={x.pictogramSourceId} height="60" key={"picto" + index} /></> : <span key={"picto" + index}>{x.class}</span>)}
                        </td>
                    }
                />
                <Column
                    field="environmental"
                    minResizableWidth={100}
                    title={props.localizations.Environmental}
                    filter="text"
                    cell={(props) => props.dataItem.environmental ? <td className="k-table-td" style={{ textAlign: "center" }}> <i className="icon-ui-general-check" /></td> : <td className="k-table-td" style={{ textAlign: "center" }}></td>}
                />
            </Grid>
        </>;

    let DocumentInfo = <>
        <h4 style={{ paddingLeft: "20px" }} className="phraseSubHeader" >{props.localizations.DocumentInfo}</h4>
            {props.documentColumns.map((column, idx) =>
                column.show && !column.noFilterOrSort && (
                    <span key={idx + "dInfo"}>
                        <h4 style={{ paddingLeft: "40px" }} className="phraseValueHeader">{column.Title}: </h4>
                        <div style={{ display: "inline-block", width: "50%", verticalAlign: "text-top" }} dangerouslySetInnerHTML={{ __html: props.selectedDoc[column.field] && typeof props.selectedDoc[column.field].getMonth === 'function' ? formatDate(props.selectedDoc[column.field]) : props.selectedDoc[column.field] }}></div>
                    </span>
                ))}
    </>;

    let properties = [];
    if (props.selectedDoc.documentProperties) {
        if (props.selectedDoc.documentProperties && props.selectedDoc.documentProperties.physicalProperties && props.selectedDoc.documentProperties.physicalProperties.length > 0) {
            properties.push({SortOrder: 1, propsHtml:
                < div ><hr style={{ borderTop: "1px solid #bbb" }} />
                    <h4 style={{ paddingLeft: "20px" }} className="phraseSubHeader">{props.localizations.physicalProperties}</h4>
                    {props.selectedDoc.documentProperties.physicalProperties.sort((a, b) => a.sortOrder - b.sortOrder).map((props3, idx) =>
                        <span key={idx + "PhProp"} >
                            <h4 style={{ paddingLeft: "40px" }} className="phraseValueHeader">{props.localizations[props3.propertyName]}:</h4>
                            <div style={{ display: "inline-block", width: "50%", verticalAlign: "text-top", whiteSpace: "pre-line" }}>{props3.propertyValue}</div>
                        </span>
                    )}
                </div >});
        }

        if (props.selectedDoc.documentProperties && props.selectedDoc.documentProperties.nfpaProperties && props.selectedDoc.documentProperties.nfpaProperties.length > 0) {
            var nfpaFlameobj = props.selectedDoc.documentProperties.nfpaProperties.find(x => x.propertyName === "Flammability");
            var nfpaFlame = "";
            if (nfpaFlameobj && nfpaFlameobj.propertyValue)
                nfpaFlame = nfpaFlameobj.propertyValue.substring(0,1);
            var nfpaHealthobj = props.selectedDoc.documentProperties.nfpaProperties.find(x => x.propertyName === "Health");
            var nfpaHealth = "";
            if (nfpaHealthobj && nfpaHealthobj.propertyValue)
                nfpaHealth = nfpaHealthobj.propertyValue.substring(0, 1);
            var nfpaInstabilityobj = props.selectedDoc.documentProperties.nfpaProperties.find(x => x.propertyName === "Instability");
            var nfpaInstability = "";
            if (nfpaInstabilityobj && nfpaInstabilityobj.propertyValue)
                nfpaInstability = nfpaInstabilityobj.propertyValue.substring(0, 1);
            var nfpaSpecialobj = props.selectedDoc.documentProperties.nfpaProperties.find(x => x.propertyName === "Special");
            var nfpaSpecial = "";
            if (nfpaSpecialobj && nfpaSpecialobj.propertyValue)
                nfpaSpecial = nfpaSpecialobj.propertyValue.substring(0, 2);
            properties.push({
                SortOrder: 2, propsHtml:
                    <div><hr style={{ borderTop: "1px solid #bbb" }} />
                        <h4 className="phraseSubHeader">{props.localizations.nfpaProperties}</h4>
                        <div className="row DocDetailsRow">
                            <div className="col-6">
                                <div className="Hazardslogo" >
                                    <img id="nfpa" src={NFPA} alt={props.localizations.nfpaProperties} className="content-image img-thumbnail rounded" />
                                    <div className="nfpaFlame"><b>{nfpaFlame}</b></div>
                                    <div className="nfpaHealth"><b>{nfpaHealth}</b></div>
                                    <div className="nfpaInstability"><b>{nfpaInstability}</b></div>
                                    <div className="nfpaSpecial"><b>{nfpaSpecial}</b></div>
                                </div>
                            </div>
                            <div className="col-6">
                                    {props.selectedDoc.documentProperties.nfpaProperties.sort((a, b) => a.sortOrder - b.sortOrder).map((props3, idx) =>
                                        <div className="row" key={idx + "nfpaprop"} >
                                            <div className="col-4" key={idx + "nfpatitle"} >
                                                <p style={{ color: "#0000ff", fontSize: "14px", fontWeight: "500" }}>{props.localizations[props3.propertyName]}:</p>
                                            </div>
                                            <div className="col-8 valueField" key={idx + "nfpavalue"} >
                                                <p style={{ color: "#2b2d42", fontFamily: "Inter" }}>
                                                    <span className="propDiv">{props3.propertyValue}</span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
            });
        }

        if (props.selectedDoc.documentProperties && props.selectedDoc.documentProperties.hmisProperties && props.selectedDoc.documentProperties.hmisProperties.length > 0) {
            var hmisFlameobj = props.selectedDoc.documentProperties.hmisProperties.find(x => x.propertyName === "Flammability");
            var hmisFlame = "";
            if (hmisFlameobj && hmisFlameobj.propertyValue)
                hmisFlame = hmisFlameobj.propertyValue.substring(0,1);
            var hmisHealthobj = props.selectedDoc.documentProperties.hmisProperties.find(x => x.propertyName === "Health");
            var hmisHealth = "";
            if (hmisHealthobj && hmisHealthobj.propertyValue)
                hmisHealth = hmisHealthobj.propertyValue.substring(0, 1);
            var hmisPHobj = props.selectedDoc.documentProperties.hmisProperties.find(x => x.propertyName === "PhysicalHazard");
            var hmisPH = "";
            if (hmisPHobj && hmisPHobj.propertyValue)
                hmisPH = hmisPHobj.propertyValue.substring(0, 1);
            var hmisPPEobj = props.selectedDoc.documentProperties.hmisProperties.find(x => x.propertyName === "PPE");
            var hmisPPE = "";
            if (hmisPPEobj)
                hmisPPE = hmisPPEobj.propertyValue.substring(0, 20);
            var hmisCronicobj = props.selectedDoc.documentProperties.hmisProperties.find(x => x.propertyName === "Chronic");
            var hmisCronic = "";
            if (hmisCronicobj && hmisCronicobj.propertyValue)
                hmisCronic = hmisCronicobj.propertyValue.substring(0, 1);
            properties.push({
                SortOrder: 3, propsHtml:
                    <div><hr style={{ borderTop: "1px solid #bbb" }} />
                        <h4 className="phraseSubHeader">{props.localizations.hmisProperties}</h4>
                        <div className="row DocDetailsRow">
                            <div className="col-6">
                                <div className="Hazardslogo" >
                                    <img id="hims" src={HIMS} alt={props.localizations.hmisProperties} className="content-image img-thumbnail rounded" />
                                    <div className="hmisFlame"><b>{hmisFlame}</b></div>
                                    <div className="hmisHealth"><b>{hmisHealth}</b></div>
                                    <div className="hmisPH"><b>{hmisPH}</b></div>
                                    <div className="hmisPPE"><b>{hmisPPE}</b></div>
                                    <div className="hmisCronic"><b>{hmisCronic}</b></div>
                                </div>
                            </div>
                            <div className="col-6">
                                {props.selectedDoc.documentProperties.hmisProperties.sort((a, b) => a.sortOrder - b.sortOrder).map((props3, idx) =>
                                    <div className="row" key={idx + "hmisprop"}>
                                        <div className="col-4" key={idx + "hmistitle"} >
                                            <p style={{ color: "#0000ff", fontSize: "14px", fontWeight: "500" }}>{props.localizations[props3.propertyName]}:</p>
                                        </div>
                                        <div className="col-8 valueField" key={idx + "hmisValue"} >
                                            <p style={{ color: "#2b2d42", fontFamily: "Inter" }}>
                                                <span className="propDiv">{props3.propertyValue}</span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
            });
        }
    }

    return (
        <div>
            {props.showModal && (
                <SpheraModal className="documentModal" title={props.localizations.Document} width={width} height={height} onClose={props.toggle} noFooter={true}
                    useMaximizeButton={maximize}
                    useMinimizeButton={minimize}
                    height={height}
                    width={width}
                    onMaximize={() => {
                        setHeight(document.documentElement.clientHeight - 1);
                        setWidth(document.documentElement.clientWidth - 1);
                        setmaximize(false);
                        setminimize(true);
                    }}
                    onMinimize={() => {
                        setHeight("90%");
                        setWidth(1000);
                        setmaximize(true);
                        setminimize(false);
                    }}
                >
                    {props.pending && <LoadingPanel localizations={props.localizations} />}
                    <SpheraTab className="documentTabs">
                        <SpheraSubTab title={props.localizations.DocumentFile} className="documentTab" >
                            <iframe src={props.documentLink} title="modaliFrame" style={{ width: "100%", height: "calc(90vh - 160px)" }} ></iframe>
                        </SpheraSubTab>
                        <SpheraSubTab title={props.localizations.documentdetails} >
                            <label onClick={showHideClick} className="sphera-label-text" style={{ cursor: "pointer" }} >{props.localizations.ShowHideSections}<i style={{ verticalAlign: "middle" }} className={showDd ? 'icon-ui-general-caret-down' : 'icon-ui-general-caret-up'}></i></label>
                            {showDd && (<>
                                <SpheraDropdownEx wrap type="multi-select" onChange={props.handlePhrasesDropdownChange} options={props.sectionsDropdownData} width="100%" value={props.phraseSectionsSelected} />
                            </>)}
                            {props.sectionsTree.map((x, idx) => {
                                return <span key={idx + "phraseSection"} ><PhraseSection pictograms={props.pictograms} section={x} key={x.phraseSectionType.name} languageValue={props.languageValue} Ingredients={Ingredients} DocumentInfo={DocumentInfo} properties={properties} Transportation={Transportation} /></span>;
                            })}
                        </SpheraSubTab>
                    </SpheraTab>
                </SpheraModal>
            )}
        </div>
    );
}