import * as React from 'react';
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";

export const ContextMenu = props => {
	React.useEffect(() => {
		document.addEventListener("click", (e) => {
			if (props.showContext && e.target.className !== 'icon-ui-general-three-dots-vertical')
				props.setshowContext(false);
		});
	}, [props.showContext]);
	
	return <Popup show={props.showContext} offset={props.offSet.current}>
		<Menu vertical={true} onSelect={props.rowContextClick} >
			{props.menuItems.map((x, index) =>
				<MenuItem text={x} key={"context" + index} />
			)}
		</Menu>
	</Popup>;
};