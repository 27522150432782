import { apiRoutes } from "../services/apiRoutes";
import { DateFormater } from "../services/DateFormater";

export const FilterData = (props) => {
    const { adjustForTimezone } = DateFormater();

    const getFilterData = async (groups) => {
        let json = await props.execute("GET", apiRoutes.filter.GetFilters);
        let filters = json.map(x => {
            if (x.timestampModified != null) {
                x.timestampModifiedDisplay = adjustForTimezone(x.timestampModified, props.customer.TimezoneId ?? 0);
                x.timestampModified = new Date(adjustForTimezone(x.timestampModified, props.customer.TimezoneId ?? 0).toDateString());
            }

            x.customerPortal = x.publicSiteFilters.map(y => y.publicSite.name).join(", ");
            x.groups = x.customerGroupFilters.map(y => groups.find(z => z.value === y.groupId).label).join(", ");

            return x;
        });

        return filters;
    }

    const getUserGroups = async () => {
        let json = await props.execute("GET", apiRoutes.filter.groups);
        for (var i = 0; i < json.length; i++) {
            json[i].value = json[i].id;
            json[i].label = json[i].name;
        }
        return json;
    }

    const deleteFilter = async (filterId) => {
        await props.execute("DELETE", apiRoutes.filter.delete(filterId));
    }

    const postFilter = async (MatchFilters, selectedFilter, localesSelected, Cc1Include, Cc2Include, Cc3Include,
        MfgInclude, TagInclude, ExactMatchExclude, Cc1Exclude, Cc2Exclude, Cc3Exclude, MfgExclude, TagExclude) => {
        selectedFilter.SearchMaterialFilters = [];

        localesSelected.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].LanguageId = x.languageId;
        });

        MatchFilters.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].ExactMatchInclude = x;
        });
        Cc1Include.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].Cc1Include = x;
        });
        Cc2Include.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].Cc2Include = x;
        });
        Cc3Include.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].Cc3Include = x;
        });
        MfgInclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].MfgInclude = x;
        });
        TagInclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].TagInclude = x;
        });
        ExactMatchExclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].ExactMatchExclude = x;
        });
        Cc1Exclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].Cc1Exclude = x;
        });
        Cc2Exclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].Cc2Exclude = x;
        });
        Cc3Exclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].Cc3Exclude = x;
        });
        MfgExclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].MfgExclude = x;
        });
        TagExclude.forEach((x, index) => {
            if (selectedFilter.SearchMaterialFilters[index] == null)
                selectedFilter.SearchMaterialFilters[index] = {};
            selectedFilter.SearchMaterialFilters[index].TagExclude = x;
        });

        await props.execute("POST", apiRoutes.filter.post(), selectedFilter);
    }

    const assignFilterToSites = async (sitesSelected, filterId) => {
        let siteIds = sitesSelected.map(x => x.value).join(",");
        if (siteIds === "")
            siteIds = null;
        await props.execute("POST", apiRoutes.filter.AssignFilterToSites(filterId), siteIds);
    }

    const assignFilterToGroups = async (groupsSelected, filterId) => {
        let groupIds = groupsSelected.map(x => x.value).join(",");
        if (groupIds === "")
            groupIds = null;
        await props.execute("POST", apiRoutes.filter.AssignFilterToGroups(filterId), groupIds );
    }

    const getFilter = (filterId, originalfilters, localesDropdownData) => {
        let filter = originalfilters.filter(x => x.id === filterId);
        if (filter.length > 0)
            filter = filter[0];
        else
            filter = {};

        let sFilter = [];
        let lSelected = [];
        let lCc1Include = [];
        let lCc2Include = [];
        let lCc3Include = [];
        let lMfgInclude = [];
        let lTagInclude = [];
        let lExactMatchExclude = [];
        let lCc1Exclude = [];
        let lCc2Exclude = [];
        let lCc3Exclude = [];
        let lMfgExclude = [];
        let lTagExclude = [];

        filter.searchMaterialFilters.forEach(x => {
            if (x.tagExclude != null)
                lTagExclude.push(x.tagExclude);
            if (x.mfgExclude != null)
                lMfgExclude.push(x.mfgExclude);
            if (x.cc3exclude != null)
                lCc3Exclude.push(x.cc3exclude)
            if (x.cc2exclude != null)
                lCc2Exclude.push(x.cc2exclude);
            if (x.cc1exclude != null)
                lCc1Exclude.push(x.cc1exclude);
            if (x.exactMatchExclude != null)
                lExactMatchExclude.push(x.exactMatchExclude)
            if (x.tagInclude != null)
                lTagInclude.push(x.tagInclude);
            if (x.mfgInclude != null)
                lMfgInclude.push(x.mfgInclude);
            if (x.cc3include != null)
                lCc3Include.push(x.cc3include);
            if (x.cc2include != null)
                lCc2Include.push(x.cc2include);
            if (x.cc1include != null)
                lCc1Include.push(x.cc1include);
            if (x.exactMatchInclude != null)
                sFilter.push(x.exactMatchInclude);
            if (x.languageId != null) {
                let item = localesDropdownData.filter(y => y.languageId == x.languageId)[0];
                lSelected.push(item);
            }
        });

        return {
            sFilter, lSelected, lCc1Include, lCc2Include, lCc3Include, lMfgInclude, lTagInclude,
            lExactMatchExclude, lCc1Exclude, lCc2Exclude, lCc3Exclude, lMfgExclude, lTagExclude, filter
        };
    }

    const getFilterTitles = (columns) => {
        let tags = columns.find(x => x.field === "materialTagsText").Title;
        let mfg = columns.find(x => x.field === "manufacturer").Title;
        let cc1 = columns.find(x => x.field === "textValue1").Title;
        let cc2 = columns.find(x => x.field === "textValue2").Title;
        let cc3 = columns.find(x => x.field === "textValue3").Title;

        return { tags, mfg, cc1, cc2, cc3 };
    }

    return {
        getFilterTitles, getFilterData, deleteFilter, postFilter, assignFilterToSites, assignFilterToGroups, getUserGroups, getFilter
    };
}