import { apiRoutes } from "../services/apiRoutes";
import menuItems from "./menuItems.json";

export const CustomerData = (props) => {
    const processColumns = (json, newLocalizations, newLocaleId) => {
        let count = 0;
        let materialColumns = [];
        let documentColumns = [];
        for (var i = 0; i < json.length; i++) {
            let newCol = {};
            newCol.width = 200;
            newCol.field = json[i].field;
            for (var j = 0; j < json[i].columnPropertyValues.length; j++) {
                newCol[json[i].columnPropertyValues[j].columnProperty.columnProperty1.trim()] = json[i].columnPropertyValues[j].columnPropertyValue1.trim();
            }

            if (newLocalizations != null && newLocalizations[Object.keys(newLocalizations).find(key => key.toLowerCase() === newCol.Title.toLowerCase())] != null)
                newCol.Title = newLocalizations[Object.keys(newLocalizations).find(key => key.toLowerCase() === newCol.Title.toLowerCase())];

            if (json[i].customerColumns.length > 0) {
                count++;
                newCol.order = json[i].customerColumns[0].columnOrder;
                newCol.width = json[i].customerColumns[0].width;
                newCol.show = json[i].customerColumns[0].selected;
            }
            else
                newCol.show = json[i].customerColumns.selected;

            if (json[i].customerColumns[0].columnLocalizations.length > 0) {
                let used = false;
                for (var j = 0; j < json[i].customerColumns[0].columnLocalizations.length; j++) {
                    if (newLocaleId == json[i].customerColumns[0].columnLocalizations[j].localeId) {
                        newCol.Title = json[i].customerColumns[0].columnLocalizations[j].localization;
                        used = true;
                    }
                }

                if (!used || !newCol.Title) {
                    let enLoc = json[i].customerColumns[0].columnLocalizations.find(x => x.localeId == 1033);
                    if (enLoc != null && enLoc.localization)
                        newCol.Title = enLoc.localization;
                    else if (json[i].customerColumns[0].columnLocalizations[0].localization)
                        newCol.Title = json[i].customerColumns[0].columnLocalizations[0].localization;
                    else
                        newCol.Title = newLocalizations[Object.keys(newLocalizations).find(key => key.toLowerCase() === newCol.Title.toLowerCase())];
                }
            }

            if (!newCol.Title)
                newCol.Title = newCol.field;

            if (json[i].gridId === 1)
                materialColumns.push(newCol);
            else
                documentColumns.push(newCol);
        }
        if (count == 0) {
            for (var i = 0; i < materialColumns.length; i++) {
                materialColumns[i].show = true;
                materialColumns[i].order = i;
            }

            for (var i = 0; i < documentColumns.length; i++) {
                documentColumns[i].show = true;
                documentColumns[i].order = i;
            }
        }
        materialColumns = materialColumns.sort((a, b) => a.order - b.order);
        documentColumns = documentColumns.sort((a, b) => a.order - b.order);
        return { materialColumns, documentColumns };
    };

    const getLocalizations = async (localeId, originalColumns) => {
        let response = await props.execute("GET", `${apiRoutes.documents.localizations}?interfaceLocaleId=${localeId}`);
        let newLocalizations = {};
        for (let i = 0; i < response.length; i++) {
            newLocalizations[response[i].key] = response[i].textValue;
        }

        let MENUS = JSON.parse(JSON.stringify(menuItems.MENUS));
        MENUS.forEach(item => {
            item.label = newLocalizations[item.label] || item.label;
        });

        let columns = processColumns(originalColumns, newLocalizations, localeId);
        return { MENUS, newLocalizations, columns };
    };

    const getColumns = async (localeId) => {
        let response = await props.execute("GET", `${apiRoutes.column.GetColumns}`);

        let { MENUS, newLocalizations, columns } = await getLocalizations(localeId, response);

        return { columns, MENUS, newLocalizations };
    }

    return {
        getLocalizations,
        getColumns
    };
}