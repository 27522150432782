import React from 'react';
import { SpheraInput, SpheraCheckbox } from "@sphera-cloud/component-ui-reactjs";
import { Col, Row } from 'reactstrap';

export function UserForm(props) {
	const usertypes = {
		SpheraAdmin: 1,
		TenentAdmin: 2,
		Tenent: 3
	};

	const [PasswordHash, setPasswordHash] = React.useState("");
	const [PasswordHasChanged, setPasswordHasChanged] = React.useState(false);

	const handleuserNameChange = (e) => {
		let user = JSON.parse(JSON.stringify(props.currentUser));
		user.userName = e.value;
		props.setcurrentUser(user);
		props.setuserNameValid(e.value.length > 0);
	};

	const handleActiveCheckboxChange = (e) => {
		let user = JSON.parse(JSON.stringify(props.currentUser));
		user.status = e.value ? 1 : 0;
		props.setcurrentUser(user);
	}

	const passwordChange = (newValue) => {
		let user = JSON.parse(JSON.stringify(props.currentUser));
		user.passwordHash = newValue.value;
		props.setcurrentUser(user);
		setPasswordHash(newValue.value);
		setPasswordHasChanged(true);
	}

	return (
		<>
			<Row>
				<Col lg="6">
					<SpheraInput required label={props.localizations.UserName} placeholder={props.localizations.UserName}
						value={props.currentUser.userName} onChange={handleuserNameChange} width="400px" valid={props.userNameValid}
						validationMessage={props.localizations.UserNameValidation} touched={!props.userNameValid} />
				</Col>
			</Row>
			<Row>
				<Col lg="6">
					{(!props.CustomerWizard) &&
						<>
							<SpheraInput
							label={props.localizations.Password} 
							title="Password"
							name="Password"
							type="password"
							placeholder={props.currentUser.passwordHash != null && props.currentUser.passwordHash.length > 0  ? props.localizations.ChangePassword : props.localizations.Password}
							onChange={passwordChange}
							value={PasswordHash}
							/>
						</>
					}
				</Col>
			</Row>
			<Row>
				{!props.CustomerWizard &&
					<>
						<Col id="phraseRbg" lg="6">
							<SpheraCheckbox label={props.localizations.Active} primary={true} value={props.currentUser.status > 0} onChange={handleActiveCheckboxChange} />
						</Col>
					</>
				}
			</Row>

		</>
	);
}
