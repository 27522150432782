import React, { useContext } from "react";
import { Layout } from "../components/MasterPage/Layout";
import { LoadingPanel } from "../components/LoadingPanel";
import { WidgetsToolbar } from "../components/Widget/WidgetsToolbar";
import WidgetCard from "../components/Widget/WidgetCard.jsx";
import { AddWidgetModal } from "../components/Modals/AddWidgetModal.jsx";
import { ConfirmDeleteWidgetDialog } from "../components/Modals/ConfirmDeleteWidgetDialog.jsx";
import { apiRoutes } from "../services/apiRoutes";
import useFetchWithMsal from "../services/useFetchWithMsal";
import WidgetContext from "../components/Widget/WidgetContext";

export const Widgets = (props) => {
  const widgetCtx = useContext(WidgetContext);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

  const [isData, setIsData] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const toggleConfirmDeleteDialog = () => {
    setShowConfirmDelete(!showConfirmDelete);
  };

  const {
    execute,
    error: errorMsal,
    isLoading: isLoading,
  } = useFetchWithMsal({
    scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
  });

  const getData = (execute) => {
    widgetCtx.loadItemsPending();
    setPending(true);
    execute("GET", apiRoutes.widgets.get)
      .then((response) => {
        if (response && response.length > 0) {
          widgetCtx.loadItems(response);
          widgetCtx.localizations = { ...props.localizations };
          setPending(false);
          setIsData(true);
        }
      })
      .catch((err) => {
        setError(true);
        setErrorMessage("props.localizations.UnableToLoadWidgets");
        widgetCtx.loadItemsRejected(props.localizations.UnableToLoadWidgets);
      })
      .finally(() => {
          setPending(false);
      });
  };

  React.useEffect(() => {
    getData(execute);
  }, [execute]);

  const addWidgetHandler = (event) => {
    event.preventDefault();
    toggleModal();
  };

  const modalCancelClick = () => {
    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const deleteWidget = (card) => {
    setPending(true);
    widgetCtx.removeItem(card.id);
    widgetCtx.setCurrent(card);
    execute("DELETE", apiRoutes.widgets.delete(card.id)).then((json) => {
      setPending(false);
      toggleConfirmDeleteDialog();
      getData(execute);
    });
  };

  const modalSaveClick = (e) => {
    const postData = {
      name: e.name,
      description: e.description,
      domain: e.domain,
      settings: "",
      isEnabled: true,
    };
    
    setPending(true);
    execute("POST", apiRoutes.widgets.post, postData)
      .then((response) => {
        if(response) {
          toggleModal();
          setPending(false);
          getData(execute);
        }
      })
      .catch((err) => {
        setError(err);
        setErrorMessage(err);
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Layout
      versionNumber={props.versionNumber} 
      currentTenent={props.currentTenent}
      localizations={props.localizations}
      changeLanguage={props.changeLanguage}
      languageValue={props.languageValue}
      error={error}
      setError={setError}
      errorMessage={errorMessage}
    >
      <WidgetsToolbar
        localizations={props.localizations}
        addWidgetHandler={addWidgetHandler}
      ></WidgetsToolbar>
      <br />

      <div
        style={{
          display: "flex",
          justifyContent: !isData ? "center" : "initial",
          flexWrap: "wrap",
          gap: "1%",
        }}
        className="cards"
      >
        {isData &&
          !pending &&
          widgetCtx.items.map((card, i) => {
            card = { ...card, isEdit: false };
            return (
              <WidgetCard
                key={card.id}
                card={card}
                toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
                localizations={props.localizations}
              ></WidgetCard>
            );
          })}

        {!isData && !pending && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: "4rem",
            }}
          >
            <div className="icon-item text-center">
              <i className="icon-outline-search"></i>
              <div
                className="font-scale-24pt"
                style={{ width: "120px", textTransform: "capitalize" }}
              >
                {props.localizations.NoData}
              </div>
            </div>
          </div>
        )}

        {pending && !isData && (
            <LoadingPanel localizations={props.localizations}  />
        )}

        {widgetCtx.status === "failed" && <p>{widgetCtx.error.json()}</p>}
      </div>

      <AddWidgetModal
        toggleModal={toggleModal}
        showModal={showModal}
        localizations={props.localizations}
        modalCancelClick={modalCancelClick}
        modalSaveClick={modalSaveClick}
      />

      {showConfirmDelete && (
        <ConfirmDeleteWidgetDialog
          card={widgetCtx.currentCard}
          toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
          deleteWidget={deleteWidget}
          localizations={props.localizations}
        ></ConfirmDeleteWidgetDialog>
      )}
    </Layout>
  );
};
