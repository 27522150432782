import React from 'react';
import { DateFormater } from "../../../services/DateFormater";

const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function TextCell(props) {
    const { formatDate } = DateFormater();
    var regEx = new RegExp("(" + escapeRegExp(props.searchedPhrase) + ")", "ig");
    let boldedText = props.DisplayValue;
    let title = props.DisplayValue;
    if (title != null && title.includes != null && title.includes("<div"))
        title = null;

    if (props.DisplayValue != null)
        boldedText = props.DisplayValue.toString().replace(regEx, '<b>$1</b>');

    if (props.isDate && props.DisplayValue != null && typeof props.DisplayValue.getMonth === 'function') {
        let dateText = formatDate(props.DisplayValue, props.customer.DateFormatId, props.adjustTimezone);
        boldedText = dateText;
        title = dateText;
    }

    if (props.isMenu) {
        return (<td><span onClick={(e) => props.linkClick(props.clickId, e, true)}
            onKeyDown={props.isLink && props.clickId != null ? (e) => { if (e.code === "Enter") props.linkClick(props.clickId) } : null}><i className="icon-ui-general-three-dots-vertical"></i></span>
        </td>);
    }

    return (
        <td> 
            {props.isCopy && <i className="icon-ui-general-copy" style={{ background: "#009BDE", marginLeft: "6px" }}
                onClick={props.isLink && props.clickId != null ? (e) => props.linkClick(props.clickId, e) : null} />
            }
            <span data-testid="gridLink" 
                    className={props.isLink && props.clickId != null ? "gridLink" : ""} 
                    onClick={props.isLink && props.clickId != null ? (e) => props.linkClick(props.clickId, e) : null}
                    onKeyDown={props.isLink && props.clickId != null ? (e) => { if (e.code === "Enter") props.linkClick(props.clickId, e) } : null}
                    dangerouslySetInnerHTML={{ __html: boldedText }}
                    onMouseMove={props.onMouseMove}
                    title={title}
                    ref={props.ref}>
            </span>
         </td>);
}

export const PopoverContext = React.createContext({
    onClick: () => null,
});

export const TextCellPopover = (props) => {
    const { formatDate } = DateFormater();
    var regEx = new RegExp("(" + escapeRegExp(props.searchedPhrase) + ")", "ig");
    
    let boldedText = props.DisplayValue;
    let title = props.DisplayValue;

    if (title != null && title.includes != null && title.includes("<div"))
        title = null;

    if (props.DisplayValue != null && props.searchedPhrase != null && props.searchedPhrase !== "")
        boldedText = props.DisplayValue.toString().replace(regEx, '<b>$1</b>');

    if (props.isDate && props.DisplayValue != null)
        boldedText = formatDate(props.DisplayValue, props.customer.DateFormatId, props.adjustTimezone);

    const { onClick: cellOnClick, value } = props;
    const tdRef = React.useRef(null);
    const { onClick } = React.useContext(PopoverContext);
    const data = props;
       

    const handleMouseMove = React.useCallback(() => {
        if (cellOnClick) {
        cellOnClick(value);
        }
        if (onClick) {
        onClick({
            tdRef: tdRef,
            date: value,
            data,
        });
        }
    }, [cellOnClick, onClick, value, data]);

    if (props.isCheckbox) {
        return (<td><input type="checkbox" className="k-checkbox k-checkbox-md k-rounded-md" role="checkbox" aria-checked="true"
            checked={props.DisplayValue} onChange={(e) => props.checkboxChange(props.clickId, e.target.checked)} /></td>);
    }
 
    return (
        <td> 
            <span data-testid="gridLink" 
                    className={props.isLink && props.clickId != null ? "gridLink" : ""} 
                    onClick={props.isLink && props.clickId != null ? () => props.linkClick(props.clickId) : null}
                    onKeyDown={props.isLink && props.clickId != null ? (e) => { if (e.code === "Enter") props.linkClick(props.clickId) } : null}
                    dangerouslySetInnerHTML={{ __html: boldedText }}
                    onMouseMove={handleMouseMove}
                    title={title}
                    ref={tdRef}>
            </span>
         </td>);
}